import * as WebBrowser from "expo-web-browser";
import React, { useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { TextInput, Button, Text, List, Appbar } from "react-native-paper";
import { createInboxSession } from "../store/actions/inbox";

export default function InboxesScreen({ route, navigation }) {
  function openInbox(inbox) {
    createInboxSession(inbox);
    navigation.navigate("Conversations", { inbox });
  }

  function Inbox(props) {
    const { inbox } = props;
    return (
      <List.Item
        title={inbox.name}
        key={inbox.slug}
        left={() => <List.Icon key={inbox.slug} icon="inbox-arrow-down" />}
        onPress={() => {
          openInbox(inbox);
        }}
      />
    );
  }

  const { inboxes } = route.params;
  return (
      <View style={{ flex: 1 }}>
        <Appbar.Header style={{ backgroundColor: "#7480FF" }}>
          <Appbar.Content title="autochat.io" color="white" />
        </Appbar.Header>
        <List.Section>
          <List.Subheader>Select Inbox</List.Subheader>
          {inboxes &&
            inboxes.map((inbox) => <Inbox key={inbox.slug} inbox={inbox} />)}
        </List.Section>
      </View>
  );
}
