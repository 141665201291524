import store from "../";
import { db, firebaseDB } from "../../firebase/db";
import * as Analytics from "expo-firebase-analytics";

import {
  CONVERSATION_ADD,
  CONVERSATION_LOADING,
  CONVERSATION_MODE,
  CONVERSATION_STATUS,
  CONVERSATION_USER_LOAD,
  CONVERSATION_USER_CLEAR,
  MESSAGE_ADD,
  STORE_STATUS_LOAD,
} from "../../constants/actions";
import { addMessage } from "../actions/messages";

let dbSubscribe = null;
let minLastSeenAt = null;
let minCreatedAt = null;
const loadMoreLimit = 20;
const chatTypes = ["open", "closed"];
let latest_conversations_fetch_started_at = Date.now();
let latest_messages_fetch_started_at = Date.now();

export const setConversationMode = (mode) => {
  return (dispatch) => {
    dispatch({ type: CONVERSATION_MODE, payload: { mode } });
  };
};

export const addConversation = (payload) => {
  return (dispatch) => {
    dispatch({ type: CONVERSATION_ADD, payload });
  };
};

const filterConversationsQuery = (query, filters) => {
  filters = filters || {};

  if (filters.chat_only) {
    let chat_status = filters.chat_type && chatTypes.includes(filters.chat_type.toLowerCase())
      ? [filters.chat_type.toLowerCase()]
      : chatTypes;
    query = query.where("inbox.status", "in", chat_status);
  }

  if (
    filters.assignedTo &&
    filters.assignedTo.name === "me"
  ) {
    query = query
      .where("outIntegration.type", "==", "InboxIntegration")
      .where("inbox.user.id", "==", filters.assignedTo.userId);
  } else if (
    filters.assignedTo &&
    filters.assignedTo.name === "unassigned"
  ) {
    query = query
      .where("outIntegration.type", "==", "InboxIntegration")
      .where("inbox.user", "==", null);
  }

  return query;
}

let unsubConversations = null;
export const getConversations = (filters = {}) => {
  return (dispatch) => {
    filters = filters || {};

    let inbox = store.getState().inbox;
    if (unsubConversations) {
      unsubConversations();
      unsubConversations = null;
    }
    //if (!filters.status) {
    //  dispatch(setConversationMode("open"));
    //  // filters.status = "open";
    //}
    dispatch({ type: CONVERSATION_LOADING });
    let query = db()
      .collection("conversations")
      .where("inbox.id", "==", inbox.session.inbox.id)
      .where("inbox.secret_key", "==", inbox.session.inbox.secret_key)
      .where("updatedAt", ">", new Date());

    query = filterConversationsQuery(query, filters);
    minLastSeenAt = null;
    setTimeout(() => {
    dispatch(loadConversationsOnRequest(inbox.session.inbox, filters)), 5000});

    try {
      unsubConversations = query.onSnapshot(
        (querySnapshot) => {
          // dispatch({
          //   type: CONVERSATION_LOADING,
          //   payload: { completed: true },
          // });
          let conversations = []
          querySnapshot.forEach(function (doc) {
            conversations.push({ id: doc.id, data: doc.data() });
          });
          dispatch({
            type: CONVERSATION_ADD,
            payload: conversations,
          });
        },
        (error) => {
          console.log("firebase error", error);
        }
      );
    } catch (error) {
      console.log("firebase error", error);
    }
    Analytics.logEvent("inbox_open", {
      inbox: inbox.inbox.slug,
      inbox_id: inbox.session.inbox.id,
      filter: filters.status,
    });
  };
};

export const loadConversationsOnRequest = (inbox, filters) => {
  let fetch_conversations_start_time = Date.now();
  latest_conversations_fetch_started_at = fetch_conversations_start_time;
  return (dispatch) => {
    let query = db()
      .collection("conversations")
      .where("inbox.id", "==", inbox.id)
      .where("inbox.secret_key", "==", inbox.secret_key)
      .orderBy("lastSeenAt", "desc");

    if (filters && filters.live_visitors) {
      query = query.where(
        "lastSeenAt",
        ">",
        new Date(new Date().getTime() - 1 * 60 * 1000)
      );
    }
    query = filterConversationsQuery(query, filters);
    query = query.startAt(minLastSeenAt || new Date()).limit(loadMoreLimit);

    query
      .get()
      .then(function (querySnapshot) {
        if (
          fetch_conversations_start_time !==
          latest_conversations_fetch_started_at
        )
          return;
        let conversations = {};
        // dispatch({
        //   type: CONVERSATION_LOADING,
        //   payload: { completed: true },
        // });
        let list = [];
        querySnapshot.forEach(function (doc) {
          let conversationData = { id: doc.id, data: doc.data() };
          list.push(conversationData);
          if (
            conversationData.data &&
            conversationData.data.lastSeenAt &&
            (!minLastSeenAt || minLastSeenAt > conversationData.data.lastSeenAt)
          ) {
            minLastSeenAt = conversationData.data.lastSeenAt;
          }
        });
        if (list.length) {
          dispatch({
            type: CONVERSATION_ADD,
            payload: list,
          });
        } else {
          dispatch({
            type: CONVERSATION_LOADING,
            payload: { completed: true },
          });
        }
        // dispatch({
        //   type: actionType.LOAD_CONVERSATIONS_SUCCESS,
        //   payload: conversations
        // });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
        if (
          error.message === "INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED" ||
          error.message === "TOKEN_EXPIRED"
        ) {
          // dispatch({
          //   type: actionType.SESSION_EXPIRY,
          //   payload: error
          // });
        } else {
          // dispatch({
          //   type: actionType.LOAD_CONVERSATIONS_FAILED,
          //   payload: error
          // });
        }
      });
  };
};

let unsubUser = null;
export const getUserData = (id) => {
  if (unsubUser) {
    unsubUser();
  }
  if (id !== null && id !== undefined) {
    return (dispatch) => {
      unsubUser = db()
        .collection("users")
        .doc(id)
        .onSnapshot(
          (doc) => {
            if (doc !== null && doc !== undefined) {
              doc.data();
              dispatch({
                type: CONVERSATION_USER_LOAD,
                payload: doc.data(),
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    };
  } else {
    alert("Failed to fetch Data");
  }
};

export const stopUserData = () => {
  return (dispatch) => {
    if (unsubUser) {
      unsubUser();
      unsubUser = null;
    }
    dispatch({
      type: CONVERSATION_USER_CLEAR,
    });
  };
};


export const updateUserActivity = (
  conversationId,
  user,
  activity = "viewing"
) => {
  return (dispatch) => {
    db()
      .collection("conversations")
      .doc(conversationId)
      .update({
        updatedAt: new Date(),
        ["inbox.activeUsers." + user.id]: {
          type: activity,
          at: new Date().getTime(),
          name: user.name,
        },
      })
      .then((doc) => {
        dispatch({
          type: "UPDATE_ACTIVE_USERS_SUCCESS",
          payload: new Date().getTime(),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const modifyConversationStatus = (
  _conversation,
  status,
  conversationId
) => {
  const newConversation = {
    ..._conversation,
    data: {
      ..._conversation,
      updatedAt: new Date(),
      responsePending: false,
      inbox: {
        ..._conversation.inbox,
        status: status,
      },
    },
  };
  const data = newConversation.data;

  return (dispatch, getState) => {
    db()
      .collection("conversations")
      .doc(conversationId)
      .update(data)
      .then((doc) => {
        dispatch({
          type: CONVERSATION_STATUS,
          payload: { [conversationId]: data },
        });

        // let currentDisplay = getState().conversation.currentDisplay;
        // if (
        //   currentDisplay == "all" ||
        //   currentDisplay == "live" ||
        //   currentDisplay === status
        // ) {
        //   let conversationData = conversation(_conversation.unique_id, data);
        //   // TODO update conversation, status
        //   dispatch({
        //     type: CONVERSATION_STATUS,
        //   });
        //   dispatch({
        //     type: actionType.LOAD_CONVERSATIONS_SUCCESS,
        //     payload: {
        //       [conversationData.unique_id]: conversationData,
        //     },
        //   });
        // } else {
        //   dispatch({
        //     type: CONVERSATION_STATUS,
        //   });
        //   dispatch({
        //     type: actionType.REMOVE_CONVERSATION,
        //     payload: {
        //       conversationId: _conversation.unique_id,
        //     },
        //   });
        // }
      })
      .catch(function (error) {
        console.log("Error updating document:", error);
        // if (
        //   error.message === "INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED" ||
        //   error.message === "TOKEN_EXPIRED"
        // ) {
        //   dispatch({
        //     type: actionType.SESSION_EXPIRY,
        //     payload: error,
        //   });
        // }
      });
  };
};

export const adddMessage = (message) => {
  return (dispatch) => {
    let key = firebaseDB().ref("/messages").push().key;

    // dispatch({ type: 'MESSAGE_UPDATE_LOCAL', payload: message });
    const messagefirebaseKey = firebaseDB(key).ref("/messages/" + key);

    // if (message.message.type !== "event") {
    //   dispatch({
    //     type: "LOAD_MESSAGE_LOCAL",
    //     payload: [
    //       {
    //         ...getMessageStructure(message, key),
    //       },
    //     ],
    //   });
    // }

    messagefirebaseKey
      .set(message)
      .then((res) => {
        // dispatch({
        //   type: MESSAGE_ADD,
        //   payload: res,
        // });
      })
      .catch((error) => {
        console.log("Error", error);
        // if (
        //   error.message === "INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED" ||
        //   error.message === "TOKEN_EXPIRED"
        // ) {
        //   dispatch({
        //     type: actionType.SESSION_EXPIRY,
        //     payload: error,
        //   });
        // } else {
        //   dispatch({
        //     type: actionType.ADD_CONVERSATION_FAILED,
        //     payload: error,
        //   });
        // }
      });
  };
};

export const addAssignedMessage = (
  conversation,
  conversationId,
  handoverto,
  session
) => {
  return (dispatch) => {
    const assignee = {
      type: conversation.outIntegration.type,
      name: conversation.outIntegration.name,
    };

    if (handoverto.inboxIntegration && handoverto.user) {
      assignee.agent = {
        name: handoverto.user.name,
        image: handoverto.user.image,
      };
    }

    const recipient = conversation.sender;
    recipient.integration = conversation.inIntegration;
    recipient.conversationId = conversationId;

    const message = {
      type: "event",
      events: [
        {
          name: "assigned",
          data: assignee,
          text: `Assigned to ${
            assignee.agent ? assignee.agent.name : assignee.name
          }`,
        },
      ],
      recipient,
      sender: {},
    };

    if (session && session.user) {
      message.sender.id = session.user.id;
    }
    dispatch(
      adddMessage({
        inbox: {
          id: conversation.inbox.id,
        },
        message,
      })
    );
  };
};

export const handoverConversation = (
  conversation,
  conversationId,
  handoverto,
  session
) => {
  if (handoverto.inboxIntegration) {
    conversation.outIntegration = handoverto.inboxIntegration;
    if (handoverto.user) {
      conversation.inbox.user = handoverto.user;
    } else {
      delete conversation.inbox.user;
    }
  } else {
    conversation.outIntegration = handoverto;
    delete conversation.inbox.user;
  }

  const data = {
    inbox: conversation.inbox,
    outIntegration: conversation.outIntegration,
  };
  //let { index } = store.getState().activeConversation;
  return (dispatch) => {
    // dispatch({
    //   type:HAND_OVER_CONVERSATION_REQUEST
    // });
    db()
      .collection("conversations")
      .doc(conversationId)
      .update(data)
      .then((doc) => {
        // if (doc.exists) {
        // dispatch({
        //   type: HAND_OVER_CONVERSATION_SUCCESS
        // });
        // dispatch({
        //   type: actionType.ACTIVE_CONVERSATION,
        //   payload: conversation,
        //   index: index
        // });

        dispatch(
          addAssignedMessage(conversation, conversationId, handoverto, session)
        );
        // } else {
        //     // doc.data() will be undefined in this case
        //     dispatch({type: actionType.UPDATE_CONVERSATION_FAILED})
        // }
      })
      .catch(function (error) {
        console.log(error);

        // if (
        //   error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
        //   error.message === 'TOKEN_EXPIRED'
        // ) {
        //   dispatch({
        //     type: actionType.SESSION_EXPIRY,
        //     payload: error
        //   });
        // } else {
        //   dispatch({
        //     type: actionType.HAND_OVER_CONVERSATION_FAILED
        //   });
        // }
      });
  };
};

