import {
  INBOX_SESSION_START,
  INBOX_SESSION_SUCCESS,
  INBOX_SESSION_FAILURE,
  USER_UPDATE_SUCCESS,
  GET_INBOX_USERS_SUCCESS,
  GET_OUT_INTEGRATIONS_SUCCESS,
  STORE_STATUS_LOAD,
} from "../../constants/actions";

const initialState = {
  loading: false,
  inbox: null,
  session: null,
  users: null,
  outIntegrations: null,
  userUpdateStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INBOX_SESSION_START:
      return { loading: true, inbox: null, session: null };
    case INBOX_SESSION_SUCCESS:
      return {
        loading: false,
        inbox: action.payload.inbox,
        users: action.payload.users,
        outIntegrations: action.payload.out_integrations,
        session: action.payload.session,
      };
    case INBOX_SESSION_FAILURE:
      return initialState;
    case USER_UPDATE_SUCCESS:
      let session = { ...state.session };
      session.user = { ...action.payload.user };
      return { ...state, session, userUpdateStatus: "success" };
    case GET_INBOX_USERS_SUCCESS:
      return { ...state, users: [...action.payload] };
    case GET_OUT_INTEGRATIONS_SUCCESS:
      return { ...state, outIntegrations: action.payload };
    case STORE_STATUS_LOAD:
      return { ...state, storeData: action.payload };
    default:
      return state;
  }
};
