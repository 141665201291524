import firebase from "firebase";
import "firebase/firestore";
import env from "../constants/env";

let config = {
  apiKey: env.firebase_api_key,
  authDomain: env.firebase_auth_domain,
  databaseURL: env.firebase_database_url,
  projectId: env.firebase_project_id,
  storageBucket: env.firebase_storage_bucket,
  messagingSenderId: env.firebase_messaging_senderid,
  appId: env.firebase_app_id,
  measurementId: env.firebase_measurementId,
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

let database;
let firebaseDatabase;

export const storage = firebase.storage();

export const init = () => {
  firebaseDatabase = firebase.database();
  database = firebase.firestore();
  //const settings = {"timestampsInSnapshots": true};
  //database.settings(settings);
  // database.enablePersistence();
};

export const db = () => {
  return database;
};

export const firebaseDB = () => {
  return firebaseDatabase;
};
