import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Image,
  Text,
  TouchableOpacity,
  Keyboard,
  ActivityIndicator,
  Linking,
  Platform,
  Dimensions,
} from "react-native";
import { Appbar, IconButton, Divider, Avatar } from "react-native-paper";
import ConversationHeader from "../components/ConversationHeader";
import { getAssignee, getAssigned } from "../helpers/Conversation";
import { ProfileImage } from "../helpers/ImageMapper";

import { connect } from "react-redux";
import {
  getMessages,
  stopMessages,
  sendMessage,
} from "../store/actions/messages";
import {
  updateUserActivity,
  modifyConversationStatus,
  handoverConversation,
  adddMessage,
  getUserData,
  stopUserData,
} from "../store/actions/conversations";
import {
  GiftedChat,
  Bubble,
  Composer,
  InputToolbar,
  Send,
  Message,
} from "react-native-gifted-chat";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
//import GestureRecognizerView from "rn-swipe-gestures";
//import EmojiBoard from "react-native-emoji-board";
import EmojiSelector, { Categories } from "react-native-emoji-selector";
import DropDownPicker from "react-native-dropdown-picker";
import * as DocumentPicker from "expo-document-picker";
import moment from "moment";
import { messageData, message } from "../javascripts/models/Message";
import { AddFileToStorage, GetFileUrl } from "../javascripts/Firestore";
// import { Audio, Video } from "expo-av";

class ConversationScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      inbox: true,
      show: false,
      assignee: "Agents",
    };
    this.controller;
  }

  async UNSAFE_componentWillMount() {
    if (
      this.props.integrations &&
      this.props.users &&
      this.props.integrations.integrations.length != 0
    ) {
      const assigneeName = getAssignee(
        this.props.conversation,
        this.props.session.user,
        this.props.integrations.integrations
      );
      const found = this.props.users.some((item) => item.id === assigneeName);
      if (found) {
        this.setState({ assignee: assigneeName });
      } else {
        this.setState({ assignee: "Agents" });
      }
    }
  }

  componentDidMount() {
    this.props.getMessages(this.props.conversationId, this.props.inbox);
    this.props.updateUserActivity(
      this.props.conversationId,
      this.props.session.user
    );
    this.props.getUserData(this.props.conversation.userId);
    this.props.navigation.setOptions({
      headerTitle: (props) => (
        <ConversationHeader
          key={this.props.conversationId}
          conversationId={this.props.conversationId}
          conversation={this.props.conversation}
          session={this.props.session}
          currentUser={this.props.conversationUser}
          headerView={true}
        />
      ),
    });
  }

  componentWillUnmount() {
    this.props.stopMessages(this.props.conversationId);
    this.props.stopUserData();
  }

  renderMessage(props) {
    const {
      currentMessage: { text: currText },
    } = props;

    let messageTextStyle;
    return <Message {...props} messageTextStyle={messageTextStyle} />;
  }

  renderBubble(props) {
    if (props.currentMessage.document) {
      return (
        <View
          style={{
            backgroundColor:
              props.currentMessage.user._id == 1 ? "#7480FF" : "white",
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            borderBottomRightRadius:
              props.currentMessage.user._id == 1 ? 0 : 15,
            borderBottomLeftRadius: props.currentMessage.user._id == 1 ? 15 : 0,
          }}
        >
          <IconButton
            icon="file-pdf"
            color={props.currentMessage.user._id == 1 ? "white" : "#7480FF"}
            size={40}
            onPress={async () => {
              const supported = await Linking.canOpenURL(
                props.currentMessage.document
              );

              if (supported) {
                await Linking.openURL(props.currentMessage.document);
              } else {
                Alert.alert(`Don't know how to open this URL: ${url}`);
              }
            }}
          />
          <Text
            style={{
              color: props.currentMessage.user._id == 1 ? "white" : "black",
              paddingHorizontal: 8,
              paddingBottom: 4,
            }}
          >
            {props.currentMessage.text}
          </Text>
        </View>
      );
    }

    return (
      <View>
        <Bubble
          {...props}
          textStyle={{
            right: {
              color: props.currentMessage.type == "note" ? "black" : "#FFFFFF",
              width:
                props.currentMessage.audio || props.currentMessage.image
                  ? 160
                  : "auto",
            },
            left: { color: "black" },
          }}
          wrapperStyle={{
            left: {
              backgroundColor: "white",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              borderBottomRightRadius: 15,
              borderBottomLeftRadius: 0,
            },
            right: {
              backgroundColor:
                props.currentMessage.type == "note" ? "#f6ef7f" : "#7480FF",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 15,
              opacity: props.currentMessage.type == "note" ? 0.8 : 1,
            },
          }}
          onLongPress={() => null}
          renderMessageVideo={(props1) => {
            const { currentMessage } = props1;
            return (
              <View
                style={{
                  padding: 10,
                }}
              >
                {/* <Video
                  source={{ uri: currentMessage.video }}
                  thumbnail={currentMessage.video}
                  resizeMode="stretch"
                  useNativeControls={true}
                  rate={1.0}
                  volume={1.0}
                  isMuted={false}
                  usePoster={true}
                  shouldPlay={false}
                  style={{
                    height: 140,
                    width: 180,
                    backgroundColor: "white",
                  }}
                /> */}
                <IconButton
                  icon="file-video"
                  color={currentMessage.user._id == 1 ? "white" : "#7480FF"}
                  size={40}
                  onPress={async () => {
                    const supported = await Linking.canOpenURL(
                      currentMessage.video
                    );

                    if (supported) {
                      await Linking.openURL(props.currentMessage.video);
                    } else {
                      Alert.alert(`Don't know how to open this URL: ${url}`);
                    }
                  }}
                />
              </View>
            );
          }}
          renderMessageAudio={(props2) => {
            const { currentMessage } = props2;
            return (
              <View
                style={{
                  padding: 10,
                }}
              >
                {/* <Audio
                source={{ uri: currentMessage.audio }}
                useNativeControls={true}
              /> */}
                <IconButton
                  icon="file-music"
                  color={currentMessage.user._id == 1 ? "white" : "#7480FF"}
                  size={40}
                  onPress={async () => {
                    const supported = await Linking.canOpenURL(
                      currentMessage.audio
                    );

                    if (supported) {
                      await Linking.openURL(currentMessage.audio);
                    } else {
                      Alert.alert(`Don't know how to open this URL: ${url}`);
                    }
                  }}
                />
              </View>
            );
          }}
        />
        <Text
          style={{
            color: "#bfbfbf",
            fontSize: 10,
            alignSelf:
              props.currentMessage.user._id == 1 ? "flex-end" : "flex-start",
          }}
        >
          {moment(new Date(`${props.currentMessage.created_at}`)).format("LT")}
        </Text>
      </View>
    );
  }

  onSend(messages = []) {
    messages.forEach((message) => {
      let payload = {
        inbox: { id: this.props.session.inbox.id },
        message: {
          type: this.state.inbox == true ? "message" : "note",
          text: message.text,
          recipient: {
            conversationId: this.props.conversationId,
            data: this.props.conversation.sender.data,
            integration: this.props.conversation.inIntegration,
          },
          sender: { id: this.props.session.user.id },
        },
      };
      this.props.sendMessage(payload, this.props.inbox);
    });
  }

  addAttachments = async (e) => {
    let mediaType = {
      image: "IMAGE",
      video: "VIDEO",
      audio: "AUDIO",
      application: "DOCUMENT",
    };
    let file = e;
    if (file.type == null) {
      alert("Unable to read file(Corrupted)! Please choose correct file");
    }
    let attachementType =
      (file && mediaType[file.type.split("/")[0]]) || mediaType["application"];
    let supportedTypes = ["IMAGE", "VIDEO", "AUDIO", "DOCUMENT"];
    let FileSize = file.size / 1024 / 1024; // in MB

    if (FileSize >= 5) {
      alert("File size exceeds 5 MB");
      return;
    } else {
      let conversation = this.props.conversation;
      if (conversation != null) {
        let text = file.name || "Untitled Document";
        let messageModel = message(text, null, {}, null, false, false, "");

        messageModel.sender = {};
        messageModel.message = {};
        const recipient = conversation.sender;
        recipient.integration = conversation.inIntegration;
        recipient.conversationId = this.props.conversationId;
        messageModel.recipient = recipient;

        let inbox = {};
        if (this.props.session != null && this.props.session.inbox != null) {
          // Read item:     let
          messageModel.sender.id = this.props.session.user.id;
          inbox.id = this.props.session.inbox.id;
        }
        if (supportedTypes.includes(attachementType)) {
          messageModel = {
            ...messageModel,
            attachment: {
              media: {
                Bytes: "",
                totalBytes: "",
                url: "",
              },
              type: attachementType,
            },
            type: "message",
            isImageUploaded: false,
            isMessageSent: false,
          };
          const responsed = await fetch(e.uri);
          const blob = await responsed.blob();
          let reference =
            "clients/" +
            this.props.conversation.sender.data.client_key +
            "/sessions/" +
            this.props.conversation.sender.data.session_id +
            "/uploads/" +
            e.name;

          let messageDataModel = messageData(inbox, messageModel, false);
          let self = this;
          AddFileToStorage(
            reference,
            blob,
            function (snapshot) {
              messageModel.attachment.media.Bytes = snapshot.bytesTransferred;
              messageModel.attachment.media.totalBytes = snapshot.totalBytes;
            },
            function (error) {
              console.log(error);
            },
            function () {
              GetFileUrl(reference, function (url) {
                messageModel.attachment.media.url = url;
                self.props.adddMessage(messageDataModel);
              });
            }
          );
        } else {
          alert("Selected file type is not supported.");
        }
      }
    }
  };

  render() {
    const { messages, messageIds } = this.props;
    console.disableYellowBox = true;

    let filterUsers = [];
    this.props.users != null &&
      this.props.users.map((x) => {
        filterUsers.push({
          label: this.props.session.user.id == x.id ? "Me" : x.name,
          value: x.id,
          data: x,
          icon: () => {
            if (x.image == null) {
              return (
                <View
                  style={{
                    padding: 2,
                    backgroundColor: "white",
                    alignItems: "center",
                    borderRadius: 60,
                  }}
                >
                  <Avatar.Text
                    size={30}
                    color={"white"}
                    label={x.name[0].toUpperCase()}
                    style={{ backgroundColor: "#ccc" }}
                  />
                </View>
              );
            } else {
              return (
                <View
                  style={{
                    padding: 2,
                    backgroundColor: "white",
                    alignItems: "center",
                    borderRadius: 60,
                  }}
                >
                  <Avatar.Image size={28} source={{ uri: x.image }} />
                </View>
              );
            }
          },
          id: x.id,
        });
      });

    const pickFile = async () => {
      let response = await DocumentPicker.getDocumentAsync({
        type: "*/*",
        copyToCacheDirectory: false,
      });

      if (response.type == "success") {
        let { name, size, uri } = response;
        const mime = require("mime");
        let nameParts = name.split(".");
        let fileType = nameParts[nameParts.length - 1];
        const imageUri = uri;
        const newImageUri =
          Platform.OS === "android"
            ? "file://" + imageUri.split("file:/").join("")
            : imageUri;
        let fileToUpload = {
          name: name,
          size: size,
          uri: uri,
          type: mime.getType(fileType),
        };
        this.addAttachments(fileToUpload);
      }
    };

    let integrations =
      (this.props.integrations && this.props.integrations.integrations) || [];
    let inboxIntegration = integrations.find(
      (i) => i.type === "InboxIntegration"
    );

    return (
      <View style={{ flex: 1, backgroundColor: "#7480FF" }}>
        <Appbar.Header style={{ height: 0, backgroundColor: "transparent" }} />
        <View
          style={{
            alignItems: "center",
            flexDirection: "row",
            ...(Platform.OS !== "android" && {
              zIndex: 10,
            }),
          }}
        >
          <Appbar.BackAction
            color="white"
            onPress={() => {
              this.props.navigation.goBack();
            }}
          />

          <View style={{ flex: 1 }}>
            <ConversationHeader
              key={this.props.conversationId}
              conversationId={this.props.conversationId}
              conversation={this.props.conversation}
              session={this.props.session}
              currentUser={this.props.conversationUser}
            />
          </View>

          <DropDownPicker
            items={[
              {
                label: "Agents",
                value: "Agents",
                data: null,
                icon: () => (
                  <View
                    style={{
                      padding: 2,
                      backgroundColor: "white",
                      alignItems: "center",
                      borderRadius: 60,
                    }}
                  >
                    <Avatar.Text
                      size={30}
                      color={"white"}
                      label={"A"}
                      style={{ backgroundColor: "#ccc" }}
                    />
                  </View>
                ),

              },
              ...filterUsers,
            ]}
            controller={(instance) => (this.controller = instance)}
            showArrow={false}
            defaultValue={this.state.assignee}
            containerStyle={{
              height: 40,
              width: "10%",
            }}
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            itemStyle={{
              justifyContent: "flex-start",
            }}
            labelStyle={{
              color: "#000",
              marginTop: 5,
            }}
            selectedLabelStyle={{
              backgroundColor: "transparent",
              color: "white",
            }}
            activeLabelStyle={{ color: "#7480FF" }}
            dropDownStyle={{
              backgroundColor: "#fafafa",
              marginTop: 8,
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
              //overflow: "visible",
              width: Dimensions.get("window").width - 120,
              position: "absolute",
              right: 0,
              // left: 0,
              top: 20,
            }}
            dropDownMaxHeight={500}
            onChangeItem={(item) => {
              this.setState({
                assignee: item.value,
              });
              let data = item.data;

              if (inboxIntegration) {
                this.props.handoverConversation(
                  this.props.conversation,
                  this.props.conversationId,
                  { user: data, inboxIntegration },
                  this.props.session
                );
              }
            }}
            searchable={true}
            searchablePlaceholder={"Search Agent"}
          />

          <Appbar.Action
            icon="information-outline"
            color="white"
            size={35}
            onPress={() => {
              this.props.navigation.navigate("UserInfo", {
                conversationId: this.props.conversationId,
              });
            }}
          />
        </View>
        {/*<GestureRecognizerView
          onSwipeLeft={(state) => {
            this.props.navigation.navigate("UserInfo", {
              conversationId: this.props.conversationId,
            });
          }}
          onSwipeRight={(state) => {
            this.props.navigation.goBack();
          }}
          config={{
            velocityThreshold: 1,
            directionalOffsetThreshold: 80,
            detectSwipeUp: false,
            detectSwipeDown: false
          }}
          style={{ flex: 1, backgroundColor: "#d8daed" }}
        >*/}
        <View style={{ flex: 1, backgroundColor: "#d8daed" }}>
          <GiftedChat
            messages={messages}
            ref={(ref) => (this.chatRef = ref)}
            text={this.state.message}
            onInputTextChanged={async (m) => {
              await this.setState({ message: m });
            }}
            user={{ _id: 1 }}
            minInputToolbarHeight={135}
            renderInputToolbar={(props) => (
              <InputToolbar
                ref={(ref) => (this.inputRef = ref)}
                text={this.state.message}
                placeholder={
                  this.state.inbox == true ? "Type Message" : "Add a Note"
                }
                onTextChanged={async (text) => {
                  await this.setState({ message: text });
                }}
                onSend={(messages) => {
                  var arr = [];
                  arr.push(messages);
                  this.onSend(arr);
                  this.setState({ message: "" });
                }}
                renderComposer={(props1) => (
                  <View style={{ width: "100%", height: "auto" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        paddingLeft: 10,
                        paddingVertical: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: this.state.inbox == true ? "bold" : "400",
                          color: this.state.inbox == true ? "#7480FF" : "black",
                        }}
                        onPress={() => {
                          this.setState({ inbox: true });
                        }}
                      >
                        Inbox
                      </Text>
                      <Text> | </Text>
                      <Text
                        style={{
                          fontWeight:
                            this.state.inbox == false ? "bold" : "400",
                          color:
                            this.state.inbox == false ? "#7480FF" : "black",
                        }}
                        onPress={() => {
                          this.setState({ inbox: false });
                        }}
                      >
                        Note
                      </Text>
                    </View>
                    <Composer
                      {...props1}
                      textInputStyle={{
                        borderBottomColor: "#7480FF",
                        borderBottomWidth: 1,
                        borderBottomLeftRadius: 4,
                        borderBottomRightRadius: 4,
                        marginRight: 10,
                        paddingVertical: Platform.OS === "ios" ? 30: 0,
                        backgroundColor:
                          this.state.inbox == true ? "transparent" : "#fff0d1",
                      }}
                      textInputProps={{
                        onFocus: async () => {
                          await this.setState({ show: false });
                        },
                      }}
                    />
                  </View>
                )}
                renderAccessory={(props2) => (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      paddingRight: 10,
                    }}
                  >
                    <TouchableOpacity onPress={pickFile}>
                      <Image
                        source={require("../assets/images/attach.png")}
                        style={{ height: 20, width: 20, zIndex: 5 }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={async () => {
                        await Keyboard.dismiss();
                        this.setState({ show: !this.state.show });
                      }}
                    >
                      <Image
                        source={require("../assets/images/emoji.png")}
                        style={{ height: 20, width: 20, marginHorizontal: 25 }}
                      />
                    </TouchableOpacity>

                    <Send
                      {...props2}
                      textStyle={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: 10,
                      }}
                      label={this.state.inbox == true ? "Send" : "Add Notes"}
                      containerStyle={{
                        backgroundColor: "#7480FF",
                        paddingHorizontal: 20,
                        borderRadius: 6,
                        height: 40,
                      }}
                    />
                  </View>
                )}
                renderActions={() => null}
                renderSend={() => null}
                alwaysShowSend={true}
                containerStyle={[
                  {
                    backgroundColor: "white",
                    borderRadius: 10,
                    margin: 7,
                    elevation: 30,
                  },
                  this.state.show == true && {
                    position: "absolute",
                    bottom: 275,
                  },
                ]}
              />
            )}

            renderBubble={this.renderBubble}
            renderChatFooter={() => (
              <View
                style={{
                  height: this.state.show == true ? 275 : 0,
                }}
              ></View>
            )}
            renderSend={() => null}
            renderLoading={() => (
              <View
                style={{
                  flex: 0.8,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ActivityIndicator size={50} color="#7480FF" />
              </View>
            )}
            scrollToBottomComponent={() => (
              <IconButton icon="chevron-down" color={"#7480FF"} size={25} />
            )}
            scrollToBottomStyle={{
              backgroundColor: "white",
              zIndex: 30,
              opacity: 1,
            }}
            alwaysShowSend={true}
            scrollToBottom={true}
            isLoadingEarlier={false}
            infiniteScroll={true}
            listViewProps={{ initialNumToRender: 5 }}
          />
        </View>
      {/*</GestureRecognizerView>*/}
        {this.state.show == true && (
          <View
            style={{
              position: "absolute",
              bottom: 0,
              height: 280,
              width: "100%",
              backgroundColor: "#ccc",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                icon="keyboard"
                onPress={() => {
                  this.setState({ show: false });
                }}
              />
              <IconButton
                icon="backspace-outline"
                onPress={() => {
                  let text = this.state.message;
                  text = [...text].slice(0, -1).join("");
                  this.setState({ message: text });
                }}
              />
            </View>
            <EmojiSelector
              columns={Platform.OS == "web" ? 20 : 10}
              showSearchBar={false}
              showSectionTitles={false}
              showHistory={true}
              onEmojiSelected={(emoji) => {
                this.setState({
                  message:
                    this.state.message != null
                      ? this.state.message + emoji
                      : emoji,
                });
              }}
            />
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let conversations = state.conversations.conversations;
  let conversationId = ownProps.route.params.conversationId;
  let conversation = conversations[conversationId];
  return {
    conversationId,
    conversation,
    conversations,
    navigation: ownProps.navigation,
    inbox: state.inbox.inbox,
    session: state.inbox.session,
    messages: state.messages.messages,
    messagesLoading: state.messages.loading,
    messageIds: state.messages.messageIds,
    users: state.inbox.users,
    integrations: state.inbox.outIntegrations,
    conversationUser: state.conversations.conversationUser,
  };
};

export default connect(mapStateToProps, {
  getMessages,
  stopMessages,
  sendMessage,
  updateUserActivity,
  modifyConversationStatus,
  getUserData,
  stopUserData,
  handoverConversation,
  adddMessage,
})(ConversationScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
});
