import React, { useState } from "react";
import {
  Platform,
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  BackHandler,
  FlatList,
  Dimensions,
  Modal,
  ScrollView,
  TouchableWithoutFeedback,
} from "react-native";
import {
  Switch,
  Text,
  Checkbox,
  ActivityIndicator,
  Appbar,
  Card,
} from "react-native-paper";
import DropDownPicker from "react-native-dropdown-picker";

import Conversation from "../components/Conversation";
import { connect } from "react-redux";
import {
  createInboxSession,
  getInboxes,
  loadInboxData,
  updateStoreStatus
} from "../store/actions/inbox";
import {
  getConversations,
  loadConversationsOnRequest,
} from "../store/actions/conversations";
import { getUsers, getOutIntegrations } from "../store/actions/session";

class ConversationsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignee_filter: "all",
      chat_type_filter: "all",
      chat_only: false,
      live_visitors: false,
    };
    this.onEndReachedCalledDuringMomentum = false;
  }

  UNSAFE_componentWillMount() {
    BackHandler.addEventListener("hardwareBackPress", this.backPressed);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener("hardwareBackPress", this.backPressed);
  }

  backPressed = () => {
    if (this.props.navigation.isFocused() == true) {
      BackHandler.exitApp();
    }
  };

  componentDidMount(props) {
    if (!this.props.noCreateInbox) {
      this.props.createInboxSession(this.props.inbox);
    } else {
      this.props.loadInboxData(this.props.inbox);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.inbox.slug != prevProps.inbox.slug) {
      this.props.createInboxSession(this.props.inbox);
    }
  }

  openConversation(conversationId) {
    this.props.navigation.navigate("Conversation", { conversationId });
  }

  gotoInboxes() {
    this.props.getInboxes(this.props.navigation);
  }

  getConversations() {
    this.props.getConversations({
      chat_only: this.state.chat_only,
      live_visitors: this.state.live_visitors,
      chat_type: this.state.chat_type_filter,
      assignedTo: {
        name: this.state.assignee_filter,
        userId: this.props.session.user.id,
      },
    });
  }

  loadConversationsOnRequest() {
    if (!this.props.session) { return; }

    this.props.loadConversationsOnRequest(
      this.props.session.inbox,
      {
        chat_only: this.state.chat_only,
        live_visitors: this.state.live_visitors,
        chat_type: this.state.chat_type_filter,
        assignedTo: {
          name: this.state.assignee_filter,
          userId: this.props.session.user.id,
        },
      }
    );
  }

  render() {
    const { session, conversations, conversationIds } = this.props;
    let isStoreOffline = this.props.storeData
      ? this.props.storeData.offline
      : true;
    return (
      <View
        style={{
          height: '100%',
          flexDirection: "column",
        }}
      >
        <Appbar.Header style={{ backgroundColor: "transparent" }}>
          <Appbar.Action
            icon="menu"
            color="#7480FF"
            onPress={() => {
              this.props.navigation.navigate("AgentInfo");
            }}
          />
          <Appbar.Content
            title={
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "85%",
                  backgroundColor: "transparent",
                }}
              >
                <Image
                  source={require("../assets/images/icon_big.png")}
                  style={{
                    width: 262,
                    height: 70,
                    resizeMode: "contain",
                  }}
                />
              </View>
            }
          />
          <Appbar.Action
            icon="repeat"
            color="#7480FF"
            onPress={() => {
              this.gotoInboxes();
            }}
          />
        </Appbar.Header>

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 10,
          }}
        >
          <Text style={{ color: "#7480FF" }}>Online</Text>
          <Switch
            ios_backgroundColor={
              isStoreOffline == false ? "#7480FF" : "#f4f3f4"
            }
            style={{
              transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
              marginHorizontal: 10,
            }}
            trackColor={{ true: "transparent", false: "#7480FF" }}
            // thumbColor={isStoreOffline == false ? "#7480FF" : "#f4f3f4"}
            value={isStoreOffline}
            onValueChange={() => {
              this.props.updateStoreStatus();
            }}
          />
          <Text style={{ color: "#686c7e" }}>Offline</Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: 10,
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Checkbox.Android
              status={this.state.chat_only ? "checked" : "unchecked"}
              onPress={async () => {
                await this.setState({ chat_only: !this.state.chat_only });
                this.getConversations();
              }}
              color="#7480FF"
              uncheckedColor="#7480FF"
            />
            <Text style={{ color: "#7480FF" }}>Show Only Chats</Text>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Checkbox.Android
              status={this.state.live_visitors ? "checked" : "unchecked"}
              onPress={async () => {
                await this.setState({
                  live_visitors: !this.state.live_visitors,
                });
                this.getConversations();
              }}
              color="#7480FF"
              uncheckedColor="#7480FF"
            />
            <Text style={{ color: "#7480FF" }}>Live Shoppers</Text>
          </View>
        </View>

        <View
          style={{
            ...(Platform.OS !== "android" && {
              zIndex: 10,
            }),
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              marginVertical: 20,
            }}
          >
            <DropDownPicker
              items={[
                {
                  label: "All Chats",
                  value: "all",
                },
                {
                  label: "Open Chats",
                  value: "open",
                },
                {
                  label: "Closed Chats",
                  value: "closed",
                },
              ]}
              defaultValue={this.state.chat_type_filter}
              containerStyle={{
                height: 40,
                width: "40%",
              }}
              disabled={this.state.chat_only == true ? false : true}
              style={{
                backgroundColor: "transparent",
                borderColor: "#7480FF",
                opacity: this.state.chat_only == true ? 1 : 0.3,
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              labelStyle={{
                color: "#000",
              }}
              selectedLabelStyle={{
                backgroundColor: "transparent",
                color: "#7480FF",
              }}
              arrowColor={"#7480FF"}
              arrowSize={20}
              activeLabelStyle={{ color: "#7480FF" }}
              dropDownStyle={{ backgroundColor: "#fafafa" }}
              onChangeItem={async (item) => {
                await this.setState({
                  chat_type_filter: item.value,
                });
                this.getConversations();
              }}
            />
            <DropDownPicker
              items={[
                {
                  label: "Assigned To Any",
                  value: "all",
                },
                {
                  label: "Assigned To Me",
                  value: "me",
                },
                {
                  label: "Unassigned",
                  value: "unassigned",
                },
              ]}
              defaultValue={this.state.assignee_filter}
              containerStyle={{ height: 40, width: "40%" }}
              style={{
                backgroundColor: "transparent",
                borderColor: "#7480FF",
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              labelStyle={{
                color: "#000",
              }}
              selectedLabelStyle={{
                backgroundColor: "transparent",
                color: "#7480FF",
              }}
              arrowColor={"#7480FF"}
              arrowSize={20}
              activeLabelStyle={{ color: "#7480FF" }}
              dropDownStyle={{ backgroundColor: "#fafafa" }}
              onChangeItem={async (item) => {
                await this.setState({
                  assignee_filter: item.value,
                });
                this.getConversations();
              }}
            />
          </View>
        </View>

        {(!session || session.loading || this.props.conversationsLoading) && (
          <View style={styles.container1}>
            <ActivityIndicator
              size="large"
              animating={true}
              color={"#7480ff"}
            />
          </View>
        )}

        {conversationIds.length == 0 ? (
          <View style={{}}>
            {!this.props.conversationsLoading && (
              <View style={{ alignItems: "center", paddingVertical: 30 }}>
                <Text style={{ padding: 10 }}>
                  <Text style={{ color: "#999" }}>
                    No chats available!
                  </Text>
                </Text>
              </View>
            )}
          </View>
        ) : (
          <FlatList
            data={conversationIds}
            initialNumToRender={10}
            extraData={this.props}
            renderItem={({ item, index }) => {
              let id = item;
              let conversation = conversations[id];

              return (
                <TouchableOpacity key={index}
                  style={{marginTop: 0, marginBottom: 0}}
                  onPress={() => {
                    this.openConversation(id);
                  }}
                >
                  <Conversation
                    key={id}
                    conversationId={id}
                    conversation={conversation}
                    session={session}
                  />
                </TouchableOpacity>
              );
            }}
            onMomentumScrollBegin={() => { this.onEndReachedCalledDuringMomentum = true; }}
            onEndReached={({ distanceFromEnd }) => {
              if(this.onEndReachedCalledDuringMomentum){
                this.loadConversationsOnRequest();
                this.onEndReachedCalledDuringMomentum = false;
              }
            }}
            onEndReachedThreshold={0.5}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    inbox: ownProps.route.params.inbox,
    noCreateInbox: ownProps.route.params.noCreateInbox,
    navigation: ownProps.navigation,
    session: state.inbox.session,
    inboxes: state.inbox.inboxes,
    conversations: state.conversations.conversations,
    conversationsLoading: state.conversations.loading,
    conversationIds: state.conversations.conversationIds,
    conversationMode: state.conversations.mode,
    storeData: state.inbox.storeData,
  };
};

export default connect(mapStateToProps, {
  createInboxSession,
  getConversations,
  loadInboxData,
  getInboxes,
  loadConversationsOnRequest,
  updateStoreStatus,
})(ConversationsScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
  container1: {
    height: "70%",
    alignItems: "center",
    justifyContent: "center",
  },
});
