export const getAssignee = (conversation, currentUser, integrations) => {
  //console.log("Recieved props", conversation, currentUser, integrations);

  let assigneeName = "NA";
  if (conversation && conversation.outIntegration) {
    let outIntegration = conversation.outIntegration;
    if (!outIntegration.type) {
      return outIntegration.id || "NA";
    }

    if (outIntegration.type === "InboxIntegration") {
      let inbox = conversation.inbox;
      if (inbox && inbox.user) {
        if (currentUser && inbox.user.id === currentUser.id) {
          assigneeName = inbox.user.id;
        } else {
          assigneeName = inbox.user.id;
        }
      } else {
        assigneeName = "Agents";
      }
    } else {
      let integration =
        integrations &&
        integrations.find(
          (i) => i.type === outIntegration.type && i.id === outIntegration.id
        );
      if (integration) {
        assigneeName = integration.name || getIntegrationName(integration.type);
      } else {
        assigneeName =
          outIntegration.name || getIntegrationName(outIntegration.type);
      }
    }
  }

  return assigneeName;
};

export const getAssigned = (conversation, currentUser, integrations) => {
  //console.log("Recieved props", conversation, currentUser, integrations);
  let assigneeName = "NA";

  if (conversation && conversation.outIntegration) {
    let outIntegration = conversation.outIntegration;
    if (!outIntegration.type) {
      return outIntegration.name || "NA";
    }

    if (outIntegration.type === "InboxIntegration") {
      let inbox = conversation.inbox;
      if (inbox && inbox.user) {
        if (currentUser && inbox.user.id === currentUser.id) {
          assigneeName = "Me";
        } else {
          assigneeName = inbox.user.name;
        }
      } else {
        assigneeName = "Agents";
      }
    } else {
      let integration =
        integrations &&
        integrations.find(
          (i) => i.type === outIntegration.type && i.id === outIntegration.id
        );
      if (integration) {
        assigneeName = integration.name || getIntegrationName(integration.type);
      } else {
        assigneeName =
          outIntegration.name || getIntegrationName(outIntegration.type);
      }
    }
  }

  return assigneeName;
};

export const getIntegrationName = (name) => {
  return name ? name.replace("Integration", "") : "";
};
