export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const SET_EXPO_PUSH_TOKEN = "SET_EXPO_PUSH_TOKEN";
export const SET_USERS = "SET_USERS";
export const GET_OUT_INTEGRATIONS_SUCCESS = "GET_OUT_INTEGRATIONS_SUCCESS";
export const GET_CURRENT_CUSTOMER_SUCCESS = "GET_CURRENT_CUSTOMER_SUCCESS";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";

export const INBOX_SESSION_START = "INBOX_SESSION_START";
export const INBOX_SESSION_SUCCESS = "INBOX_SESSION_SUCCESS";
export const INBOX_SESSION_FAILURE = "INBOX_SESSION_FAILURE";

export const CONVERSATION_MODE = "CONVERSATION_MODE";
export const CONVERSATION_ADD = "CONVERSATION_ADD";
export const CONVERSATION_LOADING = "CONVERSATION_LOADING";
export const CONVERSATION_STATUS = "CONVERSATION_STATUS";

export const CONVERSATION_USER_LOAD = "CONVERSATION_USER_LOAD";
export const CONVERSATION_USER_CLEAR = "CONVERSATION_USER_CLEAR";

export const MESSAGE_LOADING = "MESSAGE_LOADING";
export const MESSAGE_ADD = "MESSAGE_ADD";
export const MESSAGE_STOP = "MESSAGE_STOP";
export const MESSAGE_CLEAR = "MESSAGE_CLEAR";

export const GET_SEARCH_PRODUCTS_SUCCESS = "GET_SEARCH_PRODUCTS_SUCCESS";
export const GET_CUSTOMER_ORDERS_SUCCESS = "GET_CUSTOMER_ORDERS_SUCCESS";

export const GET_INBOX_LIST_SUCCESS = "GET_INBOX_LIST_SUCCESS";
export const GET_INBOX_USERS_SUCCESS = "GET_INBOX_USERS_SUCCESS";

export const STORE_STATUS_LOAD = "STORE_STATUS_LOAD";
