import { db, firebaseDB } from "../../firebase/db";
import * as Analytics from "expo-firebase-analytics";

import {
  MESSAGE_ADD,
  MESSAGE_LOADING,
  MESSAGE_CLEAR,
} from "../../constants/actions";

export const addMessage = (payload) => {
  return (dispatch) => {
    dispatch({ type: MESSAGE_ADD, payload });
  };
};

let unsubMessages = null;
export const getMessages = (conversationId, inbox) => {
  return (dispatch) => {
    if (!conversationId) {
      return;
    }
    if (unsubMessages) {
      unsubMessages();
    }
    dispatch({
      type: MESSAGE_LOADING,
      payload: { conversationId },
    });
    let query = db()
      .collection("conversations")
      .doc(conversationId)
      .collection("messages")
      .orderBy("createdAt", "asc");

    try {
      unsubMessages = query.onSnapshot((snapshot) => {
        let messageArray = [];
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            messageArray.push({ id: change.doc.id, data: change.doc.data() });
          }
        });
        dispatch({
          type: MESSAGE_ADD,
          payload: messageArray,
        });
      });
    } catch (error) {
      console.log("firebase error", error);
    }
    Analytics.logEvent("conversation_open", {
      inbox: inbox.slug,
    });
  };
};

export const stopMessages = (conversationId) => {
  return (dispatch) => {
    if (unsubMessages) {
      unsubMessages();
      unsubMessages = null;
    }
    dispatch({
      type: MESSAGE_CLEAR,
    });
  };
};

export const sendMessage = (message, inbox) => {
  return (dispatch) => {
    let key = firebaseDB().ref("/messages").push().key;

    // dispatch({ type: 'MESSAGE_UPDATE_LOCAL', payload: message });
    const messagefirebaseKey = firebaseDB(key).ref("/messages/" + key);

    //if (message.message.type !== 'event') {
    //  dispatch({
    //    type: 'LOAD_MESSAGE_LOCAL',
    //    payload: [{ ...getMessageStructure(message, key) }]
    //  });
    //}

    messagefirebaseKey
      .set(message)
      .then((res) => {
        //loadSections()(dispatch) //refresh the data to keep up-to-date
        //dispatch({ type: actionType.ADD_CONVERSATION_SUCCESS, payload: res });
      })
      .catch((error) => {
        console.log("send message error", error);
        if (
          error.message === "INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED" ||
          error.message === "TOKEN_EXPIRED"
        ) {
          //dispatch({ type: actionType.SESSION_EXPIRY, payload: error });
        } else {
          //dispatch({
          //  type: actionType.ADD_CONVERSATION_FAILED,
          //  payload: error
          //});
        }
      });
    Analytics.logEvent("conversation_reply", {
      inbox: inbox.slug,
    });
  };
};
