import {
  LOGIN_SUCCESS,
  CLEAR_SESSION,
  SET_EXPO_PUSH_TOKEN,
  GET_OUT_INTEGRATIONS_SUCCESS,
  GET_CURRENT_CUSTOMER_SUCCESS,
  GET_INBOX_LIST_SUCCESS,
} from "../../constants/actions";

const initialState = {
  cookie: null,
  email: null,
  expo_push_token: null,
  inboxes: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        cookie: action.payload.cookie,
      };
    case SET_EXPO_PUSH_TOKEN:
      return { ...state, expo_push_token: action.payload.expo_push_token };
    case CLEAR_SESSION:
      return { ...state, cookie: null, expo_push_token: null };
    case GET_INBOX_LIST_SUCCESS:
      return { ...state, inboxes: action.payload };
    default:
      return state;
  }
};
