import {
  MESSAGE_ADD,
  MESSAGE_LOADING,
  MESSAGE_CLEAR,
} from "../../constants/actions";
import avatars from "../../constants/avatars";

const initialState = {
  conversationId: null,
  messageIds: [],
  messages: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_LOADING:
      return {
        conversationId: action.payload.conversationId,
        messageIds: [],
        messages: [],
        loading: true,
      };
    case MESSAGE_ADD:
      let new_messages = action.payload;
      let messageIds = state.messageIds;
      let messages = state.messages;
      new_messages.forEach((messageObj) => {
        let mesgSubId = 1;
        let message = messageObj.data;
        let mesg = message.message && message.message.message;
        if (mesg && mesg.type === "message") {
          let new_message = {
            _id: messageObj.id,
            text: mesg.text,
            created_at: message.createdAt.toDate(),
            user: {
              _id: 1,
              name: "You",
            },
          };
          if (
            message.sender &&
            message.sender.integration &&
            (message.sender.integration.type === "ShopifyIntegration" ||
            message.sender.integration.type === "WebSdkIntegration")
          ) {
            new_message.user._id = 2;
            new_message.user.name = "Visitor";
            if (message.sender.uniqueIdentifier) {
              let imageid = message.sender.uniqueIdentifier % 18;
              new_message.user.avatar = avatars[imageid];
            }
          }
          if (mesg.attachment && mesg.attachment.type === "IMAGE") {
            new_message.image = mesg.attachment.media.url;
          }
          if (mesg.attachment && mesg.attachment.type === "DOCUMENT") {
            new_message.document = mesg.attachment.media.url;
          }
          if (mesg.attachment && mesg.attachment.type === "AUDIO") {
            new_message.audio = mesg.attachment.media.url;
          }

          if (mesg.attachment && mesg.attachment.type === "VIDEO") {
            new_message.video = mesg.attachment.media.url;
          }

          messages.unshift(new_message);
          if (mesg.attachment && mesg.attachment.type === "CAROUSEL") {
            mesg.attachment.items.forEach((item) => {
              let item_mesg = { ...new_message };
              let url =
                item.actions && item.actions.find((a) => a.type === "URL");
              item_mesg.text = `${item.title} \n ${
                url && url.data && url.data.url
              } \n ${item.subtitle} \n`;
              if (item.media && item.media.type === "THUMBNAIL") {
                item_mesg.image = item.media.url;
              }
              item_mesg._id = `${messageObj.id}-${mesgSubId++}`;
              messages.unshift(item_mesg);
            });
          }
        } else if (mesg && mesg.type === "event") {
          return mesg.events.forEach((event, i) => {
            messages.unshift({
              _id: `${messageObj.id}-${mesgSubId++}`,
              text: event.text,
              createdAt: message.createdAt.toDate(),
              system: true,
            });
          });
        } else if (mesg && mesg.type === "note") {
          let new_message = {
            _id: messageObj.id,
            text: mesg.text,
            type: mesg.type,
            created_at: message.createdAt.toDate(),
            user: {
              _id: 1,
              name: "You",
            },
          };

          messages.unshift(new_message);
        }
      });
      return {
        conversationId: state.conversationId,
        messageIds,
        messages,
        loading: false,
      };
    case MESSAGE_CLEAR:
      return {
        conversationId: null,
        messageIds: [],
        messages: [],
        loading: false,
      };
    default:
      return state;
  }
};
