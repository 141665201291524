import React, { useState } from "react";
import { View, Image } from "react-native";
import { Text, List, Divider, IconButton, Badge } from "react-native-paper";
import TimeAgo from "react-native-timeago";
import avatars from "../constants/avatars";

import { ProfileImage } from "../helpers/ImageMapper";
import { getAssigned } from "../helpers/Conversation";

var moment = require("moment");
moment.updateLocale("en", {
  relativeTime: {
    s: "Live",
    future: "%s",
    ss: "%d seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 Month",
    MM: "%d Months",
    y: "1 Year",
    yy: "%d Years",
  },
});

//import ChatMessage from '../components/Message'

import { connect } from "react-redux";

function ConversationLeftBar(props) {
  const { conversation, session, conversationId } = props;

  let imageid = 0;
  if (
    conversation &&
    conversation.sender &&
    conversation.sender.uniqueIdentifier
  ) {
    imageid = conversation.sender.uniqueIdentifier;
  } else if (conversationId) {
    for (let i = 0; i < conversationId.length; i++) {
      imageid += conversationId.charCodeAt(i);
    }
  }
  imageid = imageid % 18;

  return (
    <View
      style={{
        alignSelf: "center",
      }}
    >
      <Image
        source={avatars[imageid]}
        style={{ width: 60, height: 60 }}
        resizeMode="contain"
      />
    </View>
  );
}

function ConversationRightBar(props) {
  const { conversation, session, conversationId, currentUser } = props;

  let unread = false;
  if (!conversation.lastMessageAt) {
  } else if (!conversation.inbox || !conversation.inbox.activeUsers) {
    unread = true;
  } else {
    const activeUsers = conversation.inbox.activeUsers;

    if (session && session.user && session.user.id in activeUsers) {
      const lastActiveAt = Math.round(activeUsers[session.user.id].at / 1000);
      if (lastActiveAt < conversation.lastMessageAt.seconds) {
        unread = true;
      }
    } else {
      unread = true;
    }
  }

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 3,
        justifyContent: "flex-end",
      }}
    >
      {conversation.cart && conversation.cart.total_price != null && conversation.cart.total_price > 0 && (
        <View
          style={{
            alignItems: "center",
            paddingHorizontal: 5,
            paddingVertical: 5,
            paddingTop: 10,
            borderRadius: 6,
            // borderColor: "grey",
            // borderWidth:
            // conversation.cart && conversation.cart.total_price != 0 ? 0.5 : 0,
            alignSelf: "center",
          }}
        >
          {currentUser != null &&
            currentUser.cart &&
            currentUser.cart.items &&
            currentUser.cart.items.length != 0 && (
              <Badge style={{ zIndex: 10, marginVertical: -6 }}>
                {currentUser.cart.items.length}
              </Badge>
            )}

          <IconButton
            icon="cart-outline"
            color={"grey"}
            size={30}
            style={{
              margin: -10,
            }}
          />

          <Text
            style={{
              color: "#7480FF",
              fontWeight: "bold",
            }}
          >
            {" "}
            {conversation.cart.currency === "USD"
              ? "$"
              : conversation.cart.currency}
            {conversation.cart.total_price}{" "}
          </Text>
        </View>
      )}
      <View style={{ alignItems: "center", padding: 0 }}>
        <IconButton
          icon="circle"
          color={unread ? '#7480FF' : 'rgba(204, 204, 240, 0)'}
          size={20}
          style={{
            marginHorizontal: 5,
            marginTop: 0,
            marginBottom: 10,
          }}
        />
        <ProfileImage
          conversation={conversation}
          name={getAssigned(conversation)}
          className="outIntegration"
        />
      </View>
      <View
        style={{ height: '100%', width: 6, zIndex: 5, backgroundColor: conversation.responsePending == true ? '#7480FF' : 'transparent' }}
      />
    </View>
  );
}

export default function ConversationItem(props) {
  const {
    conversationId,
    conversation,
    session,
    headerView,
    currentUser,
  } = props;

  if (!conversation) {
    return <View></View>;
  }

  let location = null;
  let name =
    conversation.ecommerce &&
    conversation.ecommerce.customer &&
    conversation.ecommerce.customer.name;
  if (
    !name &&
    conversation.sender &&
    conversation.sender.details &&
    conversation.sender.details.name
  ) {
    if (conversation.sender.details.email) {
      name = `${conversation.sender.details.name} (${conversation.sender.details.email})`;
    } else {
      name = conversation.sender.details.name;
    }
  }
  if (
    !name &&
    conversation.ecommerce &&
    conversation.ecommerce.customer &&
    conversation.ecommerce.customer.location
  ) {
    location = conversation.ecommerce.customer.location;
  }

  return (
    <View key={conversationId}
      style={{}}
    >
      <List.Item
        key={conversationId}
        style={{padding:0, paddingLeft: 10}}
        titleStyle={{ height: 0 }}
        description={() => {
          const { conversationId, conversation, session, headerView } = props;
          let live =
            conversation.lastSeenAt &&
            new Date().getTime() - conversation.lastSeenAt.toMillis() < 60000;

          return (
            <View style={{padding: 10}}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {location != null && (
                  <IconButton
                    icon="map-marker"
                    color={"#7480FF"}
                    size={16}
                    style={{
                      marginLeft: -10,
                      marginRight: -5,
                      marginVertical: 0,
                    }}
                  />
                )}
                <Text
                  style={{
                    color: "#7480FF",
                    opacity: 5,
                    fontWeight: "bold",
                  }}
                >
                  {name != null ? name : location}
                </Text>
              </View>
              <Text>
                {!headerView && <Text>{conversation.lastMessage}</Text>}
              </Text>
              <TimeAgo
                time={
                  conversation.lastSeenAt && conversation.lastSeenAt.toDate()
                }
                hideAgo={live ? true : false}
                interval={40000}
                style={{
                  color: live ? "green" : "black",
                  fontWeight: live ? "bold" : "normal",
                }}
              />
            </View>
          );
        }}
        left={() => (
          <ConversationLeftBar
            conversationId={conversationId}
            conversation={conversation}
            session={session}
            headerView={props.headerView}
          />
        )}
        right={() => (
          <ConversationRightBar
            conversationId={conversationId}
            conversation={conversation}
            session={session}
            headerView={props.headerView}
            currentUser={currentUser}
          />
        )}
        onPress={props.onPress}
      />
      <Divider />
    </View>
  );
}
