import * as WebBrowser from "expo-web-browser";
import React, { Component, useState } from "react";
import {
  Platform,
  AsyncStorage,
  View,
  StyleSheet,
  Image,
  Switch,
  Linking,
  TouchableOpacity,
} from "react-native";
import {
  TextInput,
  Button,
  Text,
  Checkbox,
  ActivityIndicator,
  IconButton,
  Appbar,
} from "react-native-paper";
import { Notifications } from "expo";
import * as Permissions from "expo-permissions";
import Constants from "expo-constants";
import * as Analytics from "expo-firebase-analytics";

import { connect } from "react-redux";
import {
  setSession,
  clearSession,
  setSessionFromCache,
} from "../store/actions/session";
import { loadInboxSession, getInboxes } from "../store/actions/inbox";
import AppStoreLinks from "../components/AppStoreLinks";

import env from "../constants/env";

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      notifications: Platform.OS === "web" ? false : true,
      errorText: null,
      isLoading: false,
      isLoadingCache: true,
      expo_push_token: null,
    };
  }

  async login() {
    this.setState({ errorText: null, isLoading: true });
    if (this.state.notifications) {
      try {
        await this._registerForPushNotificationsAsync();
      } catch (error) {
        console.log(error);
      }
    }
    let client = {
      type: Platform.OS === "web" ? "web" : "mobile",
      platform: Constants.platform,
      platformOS: Platform.OS,
      installationId: Constants.installationId,
      deviceName: Constants.deviceName,
      appVersion: Constants.nativeAppVersion,
      buildVersion: Constants.nativeBuildVersion,
      manifestVersion: Constants.manifest.version,
    };
    fetch(`${env.app_path}/api/v1/inboxes/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
        expo_push_token: this.state.expo_push_token,
        notifications: this.state.notifications,
        client,
      }),
    })
      .then((response) => {
        this.setState({ errorText: null, isLoading: false });
        if (response.status === 200) {
          let cookie =
            Platform.OS === "web" ? "true" : response.headers.get("set-cookie");
          this.props.setSession({ email: this.state.email || null, cookie });

          return response.json();
        } else {
          return response.json().then((err) => {
            throw new Error(err.message || "Login Failed");
          });
        }
      })
      .then((data) => {
        Analytics.setUserId(data.user.id);
        Analytics.setUserProperties({
          name: data.user.name,
          email: data.user.email,
        });

        if (data.inboxes && data.inboxes.length == 1) {
          this.props.navigation.navigate("Conversations", {
            inbox: data.inboxes[0],
          });
        } else {
          this.props.navigation.navigate("Inboxes", data);
        }
      })
      .catch((err) => {
        this.setState({ errorText: err.message || "Login Failed" });
      });
  }

  logout() {
    this.props.clearSession();
    this.setState({ password: null });
  }

  gotoInboxes() {
    this.props.getInboxes(this.props.navigation);
  }

  _registerForPushNotificationsAsync = async () => {
    if (Constants.isDevice) {
      const { status: existingStatus } = await Permissions.getAsync(
        Permissions.NOTIFICATIONS
      );
      let finalStatus = existingStatus;

      if (existingStatus !== "granted") {
        const { status } = await Permissions.askAsync(
          Permissions.NOTIFICATIONS
        );

        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        alert("Failed to get push token for push notification!");
        return;
      }

      try {
        await Notifications.getExpoPushTokenAsync();
      } catch (err) {
      }
      token = await Notifications.getExpoPushTokenAsync();
      this.setState({ expo_push_token: token });
    } else {
      alert("Must use physical device for Push Notifications");
    }

    if (Platform.OS === "android") {
      Notifications.createChannelAndroidAsync("new_chat", {
        name: "New Chat Message",
        description: "Notification for new chat messages",
        sound: true,
        badge: true,
        priority: "max",
        vibrate: [0, 250, 250, 250],
      });
    }
  };

  async componentDidMount() {
    this.setState({ email: this.props.email });
    this.setState({ password: null });
    let cookie = await AsyncStorage.getItem("aiocookie");
    let inbox = await AsyncStorage.getItem("inbox");
    if (cookie) {
      this.props.setSessionFromCache({ cookie });
      if (inbox) {
        try {
          inbox = JSON.parse(inbox);
          this.props.loadInboxSession(inbox);
          this.props.navigation.navigate("Conversations", {
            inbox: inbox.inbox,
            noCreateInbox: true,
          });
        } catch (err) {}
      } else {
        this.props.getInboxes(this.props.navigation);
      }
    }
    this.setState({ isLoadingCache: false });
  }

  render() {
    if (this.state.isLoadingCache) {
      return (
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" animating={true} color={"#7480ff"} />
        </View>
      );
    }

    let isWeb = Platform.OS === "web" ? true : false;

    if (this.props.cookie) {
      return (
        <View style={{ flex: 1 }}>
          <Appbar.Header style={{ backgroundColor: "#7480FF" }}>
            <Appbar.Content title="autochat.io" color="white" />
          </Appbar.Header>

          <View
            style={{
              flex: 0.5,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              source={require("../assets/images/favicon.png")}
              style={{
                width: 50,
                height: 50,
                resizeMode: "contain",
              }}
            />
            <Text
              style={{
                color: "#7480ff",
                fontWeight: "500",
                fontSize: 40,
                paddingLeft: 5,
              }}
            >
              autochat.io
            </Text>
          </View>

          <Button
            icon="message"
            mode="contained"
            color={"#7480FF"}
            style={{ margin: 30 }}
            labelStyle={{ color: "white" }}
            onPress={() => this.gotoInboxes()}
          >
            Start Chatting
          </Button>
          <Button
            icon="logout"
            mode="contained"
            color={"#7480FF"}
            style={{ margin: 30 }}
            labelStyle={{ color: "white" }}
            onPress={() => this.logout()}
          >
            Logout
          </Button>

          <AppStoreLinks></AppStoreLinks>
        </View>
      );
    }

    return (
      <View style={{ flex: 1 }}>
        <Appbar.Header style={{ backgroundColor: "#7480FF" }}>
          <Appbar.Content title="autochat.io" color="white" />
        </Appbar.Header>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: 50,
          }}
        >
          <Image
            source={require("../assets/images/favicon.png")}
            style={{
              width: 50,
              height: 50,
              resizeMode: "contain",
            }}
          />
          <Text
            style={{
              color: "#7480ff",
              fontWeight: "500",
              fontSize: 40,
              paddingLeft: 5,
            }}
          >
            autochat.io
          </Text>
        </View>
        <View style={{ padding: 30 }}>
          <TextInput
            label="Email"
            autoCompleteType="email"
            selectionColor={"#7480FF"}
            keyboardType="email-address"
            textContentType="emailAddress"
            onChangeText={(text) => this.setState({ email: text })}
            value={this.state.email || ""}
            theme={{ colors: { primary: "#7480FF" } }}
          />
          <TextInput
            label="Password"
            autoCompleteType="password"
            selectionColor={"#7480FF"}
            autoCorrect={false}
            secureTextEntry={true}
            textContentType="password"
            onChangeText={(text) => this.setState({ password: text })}
            value={this.state.password || ""}
            style={{ marginTop: 10 }}
            theme={{ colors: { primary: "#7480FF" } }}
          />
          {!isWeb && (
            <TouchableOpacity
              onPress={() =>
                this.setState({ notifications: !this.state.notifications })
              }
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <Checkbox.Android
                status={this.state.notifications ? "checked" : "unchecked"}
                onPress={() =>
                  this.setState({ notifications: !this.state.notifications })
                }
                theme={{ colors: { primary: "#7480FF" } }}
                uncheckedColor="#7480FF"
                color="#7480FF"
              />
              <Text style={{ color: "#7480FF", width: "90%" }}>
                Receive push notifications for new chat messages on this device
              </Text>
            </TouchableOpacity>
          )}

          <Button
            mode="contained"
            loading={this.state.isLoading}
            style={{ marginTop: 10, backgroundColor: "#7480FF" }}
            onPress={() => this.login()}
          >
            Login
          </Button>
          <Text style={{ padding: 5, color: "red" }}>
            {this.state.errorText}
          </Text>
        </View>
        <Text
          style={{
            alignSelf: "center",
            alignContent: "flex-end",
            fontWeight: "bold",
            color: "#7480FF",
            textDecorationLine: "underline",
          }}
          onPress={() => {
            if (Platform.OS == "web") {
              Linking.openURL("https://app.autochat.io/users/password/new");
            } else {
              this.props.navigation.navigate("ForgotPassword");
            }
          }}
        >
          Forgot Password?
        </Text>
        <AppStoreLinks></AppStoreLinks>
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    navigation: ownProps.navigation,
    email: state.session.email,
    cookie: state.session.cookie,
  };
};

export default connect(mapStateToProps, {
  setSession,
  clearSession,
  setSessionFromCache,
  loadInboxSession,
  getInboxes,
})(LoginScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
});
