export default [
  require('../assets/images/avatars/a1.png'),
  require('../assets/images/avatars/a2.png'),
  require('../assets/images/avatars/a3.png'),
  require('../assets/images/avatars/a4.png'),
  require('../assets/images/avatars/a5.png'),
  require('../assets/images/avatars/a6.png'),
  require('../assets/images/avatars/a7.png'),
  require('../assets/images/avatars/a8.png'),
  require('../assets/images/avatars/a9.png'),
  require('../assets/images/avatars/b1.png'),
  require('../assets/images/avatars/b2.png'),
  require('../assets/images/avatars/b3.png'),
  require('../assets/images/avatars/b4.png'),
  require('../assets/images/avatars/b5.png'),
  require('../assets/images/avatars/b6.png'),
  require('../assets/images/avatars/b7.png'),
  require('../assets/images/avatars/b8.png'),
  require('../assets/images/avatars/b9.png'),
];
