import React from "react";
//import { Image } from "react-bootstrap";
import { View, Text, Image } from "react-native";
import { Avatar } from "react-native-paper";

export const IntegrationsLogoPath = {
  FacebookIntegration: require("../public/logos/facebook_messenger_logo.png"),
  WebhookIntegration: require("../public/logos/webhook_logo.png"),
  SkypeIntegration: require("../public/logos/skype_logo.png"),
  LineIntegration: require("../public/logos/line_logo.png"),
  TelegramIntegration: require("../public/logos/telegram_logo.png"),
  FlockIntegration: require("../public/logos/flock_logo.png"),
  StrideIntegration: require("../public/logos/stride_logo.png"),
  KikIntegration: require("../public/logos/kik_logo.png"),
  SlackIntegration: require("../public/logos/slack_logo.png"),
  TwitterIntegration: require("../public/logos/twitter_logo.png"),
  DialogFlowIntegration: require("../public/logos/dialog_flow_logo.png"),
  AndroidSdkIntegration: require("../public/logos/android_logo.png"),
  IosSdkIntegration: require("../public/logos/ios_logo.png"),
  WebSdkIntegration: require("../public/logos/nodejs.png"),
  CiscoSparkIntegration: require("../public/logos/cisco_spark_logo.jpg"),
  AmazonLexIntegration: require("../public/logos/amazon_lex_logo.png"),
  GmailIntegration: require("../public/logos/gmail.jpg"),
  InboxIntegration: require("../public/logos/autochat_logo.svg"),
  UserProfile: require("../public/logos/user_profile_logo.png"),
  WhatsappIntegration: require("../public/logos/2000px-WhatsApp.svg_-1.png"),
};

export const IntegrationImage = (props) => {
  return (
    <Image
      source={IntegrationsLogoPath[props.integrationName]}
      classname={props.className}
      circle
      responsive
    />
  );
};

export const ImageWithName = (props) => {
  return (
    <View style={{}}>
      <Avatar.Text
        size={30}
        label={props.name && props.name[0] ? props.name[0].toUpperCase() : ""}
        color={"white"}
        style={{ backgroundColor: "#ccc" }}
      />
    </View>
  );
};

export const ProfileImage = (props) => {
  if (IntegrationsLogoPath[props.name]) {
    return (
      <Image
        source={IntegrationsLogoPath[props.name]}
      />
    );
  } else if (
    props.conversation &&
    props.conversation &&
    props.conversation.inbox &&
    props.conversation.inbox.user &&
    props.conversation.inbox.user.image
  ) {
    return (
      <Image
        source={{ uri: props.conversation.inbox.user.image }}
        style={{ width: 30, height: 30, alignSelf: "center", borderRadius: 15 }}
        circle
      />
    );
  } else if (props.image !== null && props.image !== undefined) {
    return (
      <Image
        source={props.image}
        style={{ width: 30, height: 30, alignSelf: "center", borderRadius: 15 }}
        circle
      />
    );
  } else {
    return <ImageWithName name={props.name} circle />;
  }
};
