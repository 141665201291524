import React, { Component, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  Dimensions,
  Image,
  Platform,
} from "react-native";
import {
  ActivityIndicator,
  Appbar,
  IconButton,
  List,
  Searchbar,
  Divider,
} from "react-native-paper";
import { TabView, TabBar } from "react-native-tab-view";
import DropDownPicker from "react-native-dropdown-picker";

import { connect } from "react-redux";
import { clearSession } from "../store/actions/session";
import {
  getCustomerOrdersUrl,
  searchEcommerceProductsUrl,
} from "../helpers/Common.js";
import { getCustomerOrders, getSearchProducts } from "../store/actions/store";

import {
  getMessages,
  stopMessages,
  sendMessage,
} from "../store/actions/messages";

import {
  updateUserActivity,
  modifyConversationStatus,
  handoverConversation,
  adddMessage,
} from "../store/actions/conversations";

const initialLayout = { width: Dimensions.get("window").width };

class UserInfoScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      index: 0,
      searchQuery: "Tshirt",
      searchOrder: null,
      searchCart: null,
      filter2: "Agents",
      searchProduct: "",
      customerOrderSearchText: "",
    };
  }

  async componentDidMount() {}

  visitDetails(conversationUser) {
    const sessions = conversationUser && conversationUser.sessions;
    if (sessions) {
      let array = Object.values(sessions).map((item) => {
        return new Date(item.start_at).getFullYear();
      });
      let arrayMonths = Object.values(sessions).map((item) => {
        return new Date(item.start_at).getMonth();
      });
      let d = new Date();
      let diff = Math.abs(arrayMonths[0] - d.getMonth());
      return array[0] === d.getFullYear()
        ? diff == 1
          ? `${diff} month`
          : `${diff} months`
        : `${array[0]}`;
    } else {
      return conversationUser ? "New Visit" : '';
    }
  }

  formatDate = (h, m, s) => {
    var dd = "AM";
    var h2 = h;
    var hour = h;
    if (hour >= 12) {
      hour = h2 - 12;
      dd = "PM";
    }
    if (hour == 0) {
      hour = 12;
    }
    hour = hour < 10 ? "" + hour : hour;
    var replacement = hour + ":" + m;
    replacement += " " + dd;

    return replacement;
  };

  getVisit = (time, visit) => {
    let arrayVisit = [];
    let date = new Date(time).toUTCString();
    arrayVisit = date.toString().replace("GMT", "").split(",")[1].split(" ");

    let [hours, minutes, seconds] = arrayVisit[4].split(":");

    if (visit) {
      return (
        <View
          style={{
            paddingBottom: 10,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingBottom: 2,
            }}
          >
            <Text style={{ fontSize: 13, fontWeight: "bold" }}>
              {arrayVisit[2]} {arrayVisit[1]}
              {", "}
              {arrayVisit[3]}
            </Text>

            <Text style={{ marginLeft: 30 }}>
              {this.formatDate(hours, minutes, seconds)}
            </Text>
          </View>
          <Divider width={"50%"} />
        </View>
      );
    } else {
      return (
        <Text>
          Date:{" "}
          <Text style={{ fontWeight: "bold" }}>
            {arrayVisit[2]} {arrayVisit[1]}
            {", "}
            {arrayVisit[3]}
          </Text>
        </Text>
      );
    }
  };

  getFilteredOrdersBySearchedText = (conversationUser) => {
    let filteredOrders = (conversationUser && conversationUser.orders && conversationUser.orders) || {};
    let allOrders = conversationUser && conversationUser.orders && conversationUser.orders;
    let orderSearchText = this.state.searchOrder;
    if (orderSearchText) {
      filteredOrders = {};
      for (let key in allOrders) {
        let slicedString = allOrders[key].order_id
          .toString()
          .slice(0, orderSearchText.length);

        if (orderSearchText == slicedString) {
          filteredOrders[key] = allOrders[key];
        }
        allOrders[key].items.forEach((item) => {
          if (
            orderSearchText.toLowerCase() ===
            item.title.slice(0, orderSearchText.length).toLowerCase()
          ) {
            filteredOrders[key] = allOrders[key];
            return;
          }
        });
      }
    }
    return filteredOrders;
  };

  showOrders = (props) => {
    const conversationUser = props;
    let filteredOrders = this.getFilteredOrdersBySearchedText(conversationUser);

    const isEmpty = (value) => {
      return (
        Boolean(value && typeof value === "object") &&
        !Object.keys(value).length
      );
    };

    return (
      <View style={{ marginLeft: 20 }}>
        {conversationUser !== null &&
        conversationUser !== undefined &&
        conversationUser.orders &&
        !isEmpty(filteredOrders) ? (
          Object.values(filteredOrders)
            .reverse()
            .map((item, i) => {
              return (
                <View key={Date.now() + item.order_id}>
                  <View>
                    <Text style={{ fontWeight: "bold" }}>
                      Order# {item.order_id}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    {item.created_at
                      ? this.getVisit(item.created_at, false)
                      : "Unknown"}

                    <Text style={{ marginLeft: 10 }}>
                      Amount:{" "}
                      <Text style={{ fontWeight: "bold" }}>
                        {item.currency === "USD"
                          ? `$${item.total_price}`
                          : `${item.currency}${item.total_price}`}
                      </Text>
                    </Text>
                  </View>
                  {item.items && item.items.length > 0
                    ? item.items.map((eachItem) => (
                        <View
                          key={eachItem.product_id}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            paddingVertical: 10,
                          }}
                        >
                          <View>
                            <Image
                              style={{
                                width: 70,
                                height: 70,
                              }}
                              source={
                                eachItem.picUrl
                                  ? {
                                      uri: eachItem.picUrl,
                                    }
                                  : require("../public/icons/shoppingCart.png")
                              }
                            />
                          </View>
                          <View style={{ marginLeft: 30 }}>
                            <Text>{eachItem.title}</Text>
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: 10,
                              }}
                            >
                              <Text>Qty: {eachItem.quantity}</Text>
                              <Text
                                style={{
                                  marginLeft: 20,
                                }}
                              >
                                Price:{" "}
                                {conversationUser.cart.currency === "USD"
                                  ? `$ ${eachItem.price}`
                                  : `${conversationUser.cart.currency}${eachItem.price}`}
                              </Text>
                            </View>
                          </View>
                        </View>
                      ))
                    : null}
                </View>
              );
            })
        ) : (
          <Text>
            {this.state.searchOrder ? "No matches" : "No Orders available"}
          </Text>
        )}
      </View>
    );
  };

  getFilteredCartItems = (conversationUser) => {
    let filteredCartItems =
      (conversationUser && conversationUser.cart && conversationUser.cart.items) || [];
    let allCartItems = (conversationUser && conversationUser.cart && conversationUser.cart.items) || [];
    let cartSearchText = this.state.searchCart;

    if (cartSearchText) {
      filteredCartItems = [];
      allCartItems &&
        allCartItems.length > 0 &&
        allCartItems.forEach((item) => {
          if (
            cartSearchText.toLowerCase() ===
            item.title.slice(0, cartSearchText.length).toLowerCase()
          ) {
            filteredCartItems.push(item);
          }
        });
    }
    return filteredCartItems;
  };

  showCartContents = () => {
    const { conversationUser } = this.props;
    let fileteredCartItems = this.getFilteredCartItems(conversationUser);
    return (
      <View style={{ marginLeft: 20, marginTop: 10 }}>
        {conversationUser !== null && fileteredCartItems.length > 0 ? (
          fileteredCartItems.map((eachItem) => (
            <View style={{ flexDirection: "row" }} key={eachItem.product_id}>
              <View>
                <Image
                  style={{ width: 70, height: 70 }}
                  source={
                    eachItem.picUrl
                      ? { uri: eachItem.picUrl }
                      : require("../public/icons/shoppingCart.png")
                  }
                />
              </View>
              <View>
                <Text style={{ marginBottom: 6, marginTop: 3 }}>
                  {eachItem.title}
                </Text>

                <View style={{ flexDirection: "row" }}>
                  <Text style={{ marginRight: 0 }}>
                    Qty:{" "}
                    <Text style={{ fontWeight: "bold" }}>
                      {eachItem.quantity}
                    </Text>
                  </Text>

                  <Text style={{ marginLeft: 20 }}>
                    Price:{" "}
                    <Text style={{ fontWeight: "bold" }}>
                      {conversationUser.cart.currency === "USD"
                        ? `$${eachItem.price}`
                        : `${conversationUser.cart.currency}${eachItem.price}`}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ))
        ) : (
          <Text>
            {this.state.searchCart
              ? "No Matches"
              : "No items available in the Cart"}
          </Text>
        )}
      </View>
    );
  };

  getSearchProducts() {
    const url = searchEcommerceProductsUrl(
      this.props.conversationId,
      this.state.searchProduct
    );
    this.props.getSearchProducts(url, this.props.session.api_token);
  }
  getCustomerOrders(eventKey, e) {
    this.props.getCustomerOrders(
      getCustomerOrdersUrl(this.props.conversationId),
      this.props.session.api_token
    );
  }
  showSearchBox(eventKey, e) {
    this.getSearchProducts();
  }

  getFilteredProducts = () => {
    let { products } = this.props.products;
    let { searchProduct } = this.state;
    let filteredProducts = products || [];
    let allProducts = products;
    if (searchProduct) {
      filteredProducts = allProducts.filter((item) =>
        item.name.toLowerCase().includes(searchProduct.toLocaleLowerCase())
      );
    }
    return filteredProducts;
  };

  shouldDisplayProducts() {
    let filteredProducts = this.getFilteredProducts();
    if (
      this.props.products &&
      !this.props.products.products &&
      this.props.products.status !== "success" &&
      this.props.products.status !== "error"
    ) {
      return <ActivityIndicator size={"large"} color="#7480FF" />;
    }

    if (
      this.props.products !== undefined &&
      this.props.products.status === "success"
    ) {
      return (
        <View>
          {filteredProducts && filteredProducts.length > 0 ? (
            filteredProducts.map((product) => {
              return (
                <View
                  key={product.id}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingVertical: 7,
                  }}
                >
                  <View>
                    <Image
                      source={{ uri: product.imageUrl }}
                      style={{ height: 70, width: 70 }}
                    />
                  </View>
                  <View style={{ marginLeft: 20 }}>
                    <Text>{product.name}</Text>
                    <View
                      style={{
                        flexDirection: "row",
                        paddingVertical: 5,
                      }}
                    >
                      <Text>
                        Qty:{" "}
                        <Text style={{ fontWeight: "bold" }}>
                          {product.variants[0].stockQuantity}
                        </Text>
                      </Text>
                      <Text style={{ marginLeft: 20 }}>
                        Price:{" "}
                        <Text style={{ fontWeight: "bold" }}>
                          $ {product.variants[0].price}
                        </Text>
                      </Text>
                    </View>
                    <Text
                      className="add-product-message"
                      onClick={() => this.insertProductAsCarousel(product)}
                    >
                      Add to Message
                    </Text>
                  </View>
                  <Divider height={20} />
                </View>
              );
            })
          ) : (
            <Text>No searched products</Text>
          )}
        </View>
      );
    } else if (this.props.products && this.props.products.status === "error") {
      return <Text>{this.props.products.error}</Text>;
    }
    return null;
  }

  getSession = (time) => {
    let date = new Date(time).toUTCString();
    return date.toString().replace("GMT", "");
  };

  getFilteredOrders = () => {
    let { orders } = this.props.orders;
    let { customerOrderSearchText } = this.state;
    let filteredOrders = orders || [];
    let allOrders = orders;

    if (customerOrderSearchText) {
      filteredOrders = [];
      for (let i = 0; i < allOrders.length; i++) {
        let slicedString = allOrders[i].id
          .toString()
          .slice(0, customerOrderSearchText.length);

        if (customerOrderSearchText == slicedString) {
          filteredOrders.push(allOrders[i]);
        }
        allOrders[i].lineItems.forEach((item) => {
          if (
            customerOrderSearchText.toLowerCase() ===
            item.name.slice(0, customerOrderSearchText.length).toLowerCase()
          ) {
            filteredOrders.push(allOrders[i]);
            return;
          }
        });
      }
    }
    return filteredOrders;
  };

  shouldDisplayOrders() {
    let filteredOrders = this.getFilteredOrders();
    if (
      this.props.orders.status !== "success" &&
      this.props.orders.status !== "error" &&
      filteredOrders.length == 0
    ) {
      return <ActivityIndicator />;
    }

    if (
      this.props.orders !== undefined &&
      this.props.orders.status === "success"
    ) {
      return (
        <View style={{ marginLeft: 10, marginTop: 10 }}>
          {filteredOrders && filteredOrders.length > 0 ? (
            filteredOrders.map((order) => {
              return (
                <View
                  style={{ paddingVertical: 10 }}
                  key={Date.now() + order.id}
                >
                  <View>
                    <Text style={{ fontWeight: "bold" }}>#{order.id}</Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text>
                      {order.createdAt
                        ? this.getVisit(order.createdAt)
                        : "Unknown"}
                    </Text>
                    <Text style={{ marginLeft: 20 }}>
                      Amount:{" "}
                      <Text style={{ fontWeight: "bold" }}>
                        {order.currency === "USD"
                          ? `$${order.totalPrice}`
                          : `${order.currency}${order.totalPrice}`}
                      </Text>
                    </Text>
                  </View>
                  {order.lineItems && order.lineItems.length > 0
                    ? order.lineItems.map((eachItem) => (
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                          key={eachItem.productId}
                        >
                          <View>
                            <Image
                              source={
                                eachItem.imageUrl
                                  ? { uri: eachItem.imageUrl }
                                  : require("../public/icons/cart.png")
                              }
                              style={{ height: 70, width: 70 }}
                            />
                          </View>
                          <View>
                            <Text>{eachItem.name}</Text>
                            <View
                              style={{
                                marginTop: 6,
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Text>
                                Qty:{" "}
                                <Text
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {eachItem.quantity}
                                </Text>
                              </Text>
                              <Text style={{ marginLeft: 20 }}>
                                Price:{" "}
                                <Text style={{ fontWeight: "bold" }}>
                                  {order.currency === "USD"
                                    ? `$${eachItem.price}`
                                    : `${order.currency}${eachItem.price}`}{" "}
                                </Text>
                              </Text>
                            </View>
                          </View>
                        </View>
                      ))
                    : null}

                  <Divider />
                </View>
              );
            })
          ) : (
            <Text>No Orders</Text>
          )}
        </View>
      );
    } else if (
      this.props.orders !== undefined &&
      this.props.orders.status === "error"
    ) {
      return <Text>{this.props.orders.error}</Text>;
    }
    return null;
  }

  render() {
    const {
      conversationId,
      conversation,
      session,
      headerView,
      currentUser,
    } = this.props;

    let location = null;
    let name = null;
    name =
      conversation.ecommerce &&
      conversation.ecommerce.customer &&
      conversation.ecommerce.customer.name;
    if (
      !name &&
      conversation.sender &&
      conversation.sender.details &&
      conversation.sender.details.name
    ) {
      if (conversation.sender.details.email) {
        name = `${conversation.sender.details.name} (${conversation.sender.details.email})`;
      } else {
        name = conversation.sender.details.name;
      }
    }
    if (
      !name &&
      conversation.ecommerce &&
      conversation.ecommerce.customer &&
      conversation.ecommerce.customer.location
    ) {
      location = conversation.ecommerce.customer.location;
    }

    if (this.state.isLoadingCache) {
      return (
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" />
        </View>
      );
    }

    return (
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <Appbar.Header
          style={{
            backgroundColor: "#7480FF",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Appbar.BackAction
            color="white"
            onPress={() => {
              this.props.navigation.goBack();
            }}
          />
          {this.props.conversation &&
            this.props.conversation.inbox &&
            this.props.conversation.inbox.status &&
            this.props.conversation.inbox.status == "open" && (
              <Text
                style={{ color: "white", paddingRight: 5 }}
                onPress={() => {
                  this.props.modifyConversationStatus(
                    this.props.conversation,
                    "closed",
                    this.props.conversationId
                  );
                }}
              >
                Mark Close
              </Text>
            )}
          {this.props.conversation &&
            this.props.conversation.inbox &&
            this.props.conversation.inbox.status &&
            this.props.conversation.inbox.status == "closed" && (
              <Text
                style={{ color: "white", paddingRight: 5 }}
                onPress={() => {
                  this.props.modifyConversationStatus(
                    this.props.conversation,
                    "open",
                    this.props.conversationId
                  );
                }}
              >
                Mark Open
              </Text>
            )}
        </Appbar.Header>
        <TabView
          navigationState={{
            index: this.state.index,
            routes: [
              { key: "first", title: "User Info" },
              { key: "second", title: "Store Info" },
            ],
          }}
          renderTabBar={(props) => (
            <TabBar
              {...props}
              indicatorStyle={{ backgroundColor: "#7480FF", height: 3 }}
              style={{ backgroundColor: "white" }}
              labelStyle={{
                color: "black",
                fontSize: Dimensions.get("window").width / 30,
                fontWeight: "bold",
              }}
              selectedLabelStyle={{ color: "red" }}
              getLabelText={({ route }) => route.title}
            />
          )}
          renderScene={({ route }) => {
            switch (route.key) {
              case "first":
                return (
                  <View style={[styles.scene, {}]}>
                    <View style={{ alignItems: "center", paddingVertical: 10 }}>
                      {name != null && <Text>{name}</Text>}
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          paddingVertical: 5,
                        }}
                      >
                        <IconButton
                          icon="map-marker"
                          color={"#7480FF"}
                          size={16}
                          style={{
                            marginLeft: -10,
                            marginRight: -5,
                            marginVertical: 0,
                          }}
                        />
                        {location != null ? (
                          <Text>{location}</Text>
                        ) : (
                          <Text>
                            {this.props.conversationUser &&
                              this.props.conversationUser.location.city}
                          </Text>
                        )}
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <View style={{ alignItems: "center" }}>
                        <Text style={{ fontWeight: "bold" }}>User Since</Text>
                        <Image
                          source={require("../public/icons/calendar.png")}
                          style={{ height: 30, width: 30, margin: 10 }}
                        />
                        <Text style={{ color: "#7480FF" }}>
                          {this.visitDetails(this.props.conversationUser)}
                        </Text>
                      </View>
                      <View style={{ alignItems: "center" }}>
                        <Text style={{ fontWeight: "bold" }}>Total Spend</Text>
                        <IconButton
                          icon="currency-usd"
                          color={"#7480FF"}
                          size={30}
                          disabled={true}
                          style={{ opacity: 1, margin: 0 }}
                        />
                        <Text style={{ color: "#7480FF" }}>
                          {this.props.conversationUser !== null &&
                          this.props.conversationUser !== undefined &&
                          this.props.conversationUser.orders_summary &&
                          this.props.conversationUser.orders_summary.all
                            ? this.props.conversationUser.cart &&
                              this.props.conversationUser.cart.currency === "USD"
                              ? `$ ${this.props.conversationUser.orders_summary.all.total_price.toFixed(
                                  2
                                )}`
                              : this.props.conversationUser.orders_summary.all.total_price.toFixed(
                                  2
                                )
                            : "$ 0"}
                        </Text>
                      </View>
                      <View style={{ alignItems: "center" }}>
                        <Text style={{ fontWeight: "bold" }}>Total Orders</Text>
                        <Image
                          source={require("../public/icons/cart.png")}
                          style={{ height: 30, width: 30, margin: 10 }}
                        />
                        <Text style={{ color: "#7480FF" }}>
                          {this.props.conversationUser !== null &&
                          this.props.conversationUser !== undefined &&
                          this.props.conversationUser.orders
                            ? Object.keys(this.props.conversationUser.orders).length
                            : 0}{" "}
                        </Text>
                      </View>
                    </View>
                    <List.AccordionGroup>
                      <List.Accordion
                        title="Cart"
                        id="1"
                        titleStyle={{ fontWeight: "bold", fontSize: 20 }}
                        theme={{ colors: { primary: "#7480FF" } }}
                      >
                        <Searchbar
                          placeholder="Search"
                          onChangeText={(query) => {
                            this.setState({ searchCart: query });
                          }}
                          value={this.state.searchCart}
                          clearIcon="close"
                          style={{
                            elevation: 0,
                            borderBottomColor: "grey",
                            borderBottomWidth: 0.5,
                          }}
                        />

                        {this.showCartContents()}
                      </List.Accordion>
                      <List.Accordion
                        title="Visits"
                        id="2"
                        titleStyle={{ fontWeight: "bold", fontSize: 20 }}
                        theme={{ colors: { primary: "#7480FF" } }}
                      >
                        {this.props.conversationUser !== null &&
                        this.props.conversationUser !== undefined &&
                        this.props.conversationUser.sessions &&
                        Object.values(this.props.conversationUser.sessions).length >
                          0 ? (
                          Object.values(this.props.conversationUser.sessions)
                            .reverse()
                            .map((item) => (
                              <View style={{ marginLeft: 20 }}>
                                {/* {this.getSession(item.start_at)} */}
                                {this.getVisit(item.start_at, true)}
                              </View>
                            ))
                        ) : (
                          <Text>No Visits till now</Text>
                        )}
                      </List.Accordion>
                      <List.Accordion
                        title="Orders"
                        id="3"
                        titleStyle={{ fontWeight: "bold", fontSize: 20 }}
                        theme={{ colors: { primary: "#7480FF" } }}
                      >
                        <Searchbar
                          placeholder="Search"
                          onChangeText={(query) => {
                            this.setState({ searchOrder: query });
                          }}
                          value={this.state.searchOrder}
                          clearIcon="close"
                          style={{
                            elevation: 0,
                            borderBottomColor: "grey",
                            borderBottomWidth: 0.5,
                          }}
                        />

                        {this.showOrders(this.props.conversationUser)}
                      </List.Accordion>
                    </List.AccordionGroup>
                  </View>
                );
              case "second":
                return (
                  <View style={[styles.scene, {}]}>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingVertical: 20,
                        ...(Platform.OS !== "android" && {
                          zIndex: 10,
                        }),
                      }}
                    >
                      <DropDownPicker
                        items={[
                          {
                            label: "Products",
                            value: "Products",
                          },
                          {
                            label: "Orders",
                            value: "Orders",
                          },
                        ]}
                        //defaultValue={this.state.filter2}
                        placeholder={"Select"}
                        placeholderStyle={{ color: "#7480FF" }}
                        containerStyle={{
                          height: 50,
                          width: "45%",
                          marginLeft: 10,
                        }}
                        style={{
                          backgroundColor: "white",
                          borderColor: "#7480FF",
                        }}
                        itemStyle={{
                          justifyContent: "flex-start",
                        }}
                        labelStyle={{
                          color: "#000",
                        }}
                        selectedLabelStyle={{
                          backgroundColor: "transparent",
                          color: "#7480FF",
                        }}
                        arrowColor={"#7480FF"}
                        arrowSize={20}
                        activeLabelStyle={{ color: "#7480FF" }}
                        dropDownStyle={{
                          backgroundColor: "#fafafa",
                          marginTop: 2,
                          overflow: "visible",
                        }}
                        dropDownMaxHeight={500}
                        onChangeItem={(item) => {
                          this.setState({
                            filter2: item.value,
                          });
                          if (item.value == "Products") {
                            this.showSearchBox();
                          } else if (item.value == "Orders") {
                            this.getCustomerOrders();
                          }
                        }}
                      />
                      <Searchbar
                        placeholder="Search"
                        onChangeText={(query) => {
                          if (this.state.filter2 == "Products") {
                            this.setState({ searchProduct: query });
                          } else if (this.state.filter2 == "Orders") {
                            this.setState({ customerOrderSearchText: query });
                          }
                        }}
                        placeholderTextColor="#7480FF"
                        iconColor="#7480FF"
                        value={
                          this.state.filter2 == "Products"
                            ? this.state.searchProduct
                            : this.state.customerOrderSearchText
                        }
                        clearIcon="close"
                        style={{
                          elevation: 0,
                          borderColor: "#7480FF",
                          borderWidth: 1,
                          width: "45%",
                          marginRight: 10,
                        }}
                      />
                    </View>
                    {this.props.products &&
                      this.props.products != null &&
                      this.state.filter2 == "Products" &&
                      this.shouldDisplayProducts()}

                    {this.props.orders &&
                      this.props.orders != null &&
                      this.state.filter2 == "Orders" &&
                      this.shouldDisplayOrders()}
                  </View>
                );

              default:
                return null;
            }
          }}
          onIndexChange={async (index) => {
            this.setState({ index: index });
          }}
          initialLayout={initialLayout}
        />
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let conversations = state.conversations.conversations;
  let conversationId = ownProps.route.params.conversationId;
  let conversation = conversations[conversationId];
  return {
    conversationId,
    conversation,
    conversations,
    cookie: state.session.cookie,
    inbox: state.inbox.inbox,
    session: state.inbox.session,
    conversationUser: state.conversations.conversationUser,
    products: state.store.products,
    orders: state.store.orders,
    users: state.session.users,
    integrations: state.session.outIntegrations,
  };
};

export default connect(mapStateToProps, {
  clearSession,
  getCustomerOrders,
  getCustomerOrdersUrl,
  getSearchProducts,
  searchEcommerceProductsUrl,
  modifyConversationStatus,
  updateUserActivity,
})(UserInfoScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
  scene: {
    flex: 1,
  },
});
