import { AsyncStorage } from "react-native";
import firebase from "firebase";
import store from "../";
import { getConversations } from "./conversations";
import env from "../../constants/env";
import { Platform } from "react-native";
import { db, firebaseDB } from "../../firebase/db";

import {
  LOGIN_SUCCESS,
  INBOX_SESSION_START,
  INBOX_SESSION_SUCCESS,
  INBOX_SESSION_FAILURE,
  GET_INBOX_LIST_SUCCESS,
  GET_INBOX_USERS_SUCCESS,
  GET_OUT_INTEGRATIONS_SUCCESS,
  STORE_STATUS_LOAD,
} from "../../constants/actions";
import { clearSession } from "./session";

export const loadInboxSession = (payload) => {
  return (dispatch) => {
    dispatch({ type: INBOX_SESSION_SUCCESS, payload });
  };
};

export const getInboxes = (navigation, auto_navigate = true) => {
  return (dispatch) => {
    let { cookie } = store.getState().session;
    fetch(`${env.app_path}/api/v1/inboxes`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cookie: cookie,
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          dispatch(clearSession());
          navigation.navigate("Login");
        } else {
          navigation.navigate("Login");
        }
      })
      .then(async (data) => {
        if (!data) {
          return;
        }
        dispatch({
          type: GET_INBOX_LIST_SUCCESS,
          payload: data.inboxes,
        });
        if (!data || !data.inboxes) {
          alert("You are not part of any inbox team");
          navigation.navigate("Login");
          return;
        }

        if (auto_navigate && data.inboxes.length == 1) {
          navigation.navigate("Conversations", { inbox: data.inboxes[0] });
        } else {
          navigation.navigate("Inboxes", data);
        }
      });
  };
};

export const createInboxSession = (inbox) => {
  return (dispatch) => {
    dispatch({ type: INBOX_SESSION_START });

    let { cookie } = store.getState().session;
    fetch(`${env.app_path}/api/v1/inbox/${inbox.slug}/session`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cookie: cookie,
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          dispatch(clearSession());
          navigation.navigate("Login");
          throw new Error("Session error");
        } else {
          throw new Error("Inbox Auth Failed");
        }
      })
      .then(async (session) => {
        await AsyncStorage.setItem("inbox", JSON.stringify({ inbox, session }));
        firebase
          .auth()
          .signInWithCustomToken(session.token)
          .then(() => {
            dispatch({
              type: INBOX_SESSION_SUCCESS,
              payload: { inbox, session },
            });
            dispatch(loadInboxData(inbox));
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const loadInboxData = (inbox) => {
  return (dispatch) => {
    dispatch(getConversations());
    dispatch(loadStoreStatus());
    dispatch(getOutIntegrations(inbox));
    dispatch(getUsers(inbox))
  };
}

export const getUsers = (inbox) => {
  return (dispatch) => {
    let { cookie } = store.getState().session;
    fetch(`${env.app_path}/api/v1/inbox/${inbox.slug}/users`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cookie: cookie,
      },
    })
      .then()
      .then((res) => res.json())
      .then((json) => {
        dispatch({ type: GET_INBOX_USERS_SUCCESS, payload: json.users });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getOutIntegrations = (inbox) => {
  return (dispatch) => {
    let { cookie } = store.getState().session;
    fetch(`${env.app_path}/api/v1/inbox/${inbox.slug}/out_integrations`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cookie: cookie,
      },
    })
      .then()
      .then((res) => res.json())
      .then((json) => {
        dispatch({
          type: GET_OUT_INTEGRATIONS_SUCCESS,
          payload: json,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const loadStoreStatus = () => {
  return (dispatch) => {
    let inbox = store.getState().inbox.session.inbox;
    if (inbox) {
      var ref = firebaseDB().ref(`inbox/${inbox.id}`);
      try {
        ref.on("value", function (snapshot) {
          let data = snapshot.val();
          if (data === null) data = { offline: false };
          dispatch({
            type: STORE_STATUS_LOAD,
            payload: data,
          });
        });
      } catch (e) {
        alert("Errer");
        console.log("load store status error", e);
      }
    }
  };
};

export const updateStoreStatus = () => {
  const session = store.getState().inbox.session;
  let inbox = session.inbox;
  if (!inbox) return;
  let storeData = store.getState().conversations.storeData;
  let status = storeData && storeData.offline;

  return (dispatch) => {
    let user = {};
    if (session && session.user) {
      user.id = session.user.id;
      user.email = session.user.email;
    }
    inbox = { id: inbox.id, name: inbox.name };
    let data = {
      updatedBy: user,
      updatedAt: new Date().getTime(),
      offline: !status,
      inbox,
    };
    firebaseDB()
      .ref(`/inbox/${inbox.id}`)
      .set(data)
      .then((res) => {
        //console.log('store status updated', res);
      })
      .catch((e) => {
        console.log("error in store status update", e);
      });
  };
};
