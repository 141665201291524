import { AsyncStorage } from "react-native";
import env from "../../constants/env";
import store from "../";

import {
  LOGIN_SUCCESS,
  CLEAR_SESSION,
  SET_EXPO_PUSH_TOKEN,
  INBOX_SESSION_FAILURE,
  USER_UPDATE_SUCCESS,
  GET_CURRENT_CUSTOMER_SUCCESS,
} from "../../constants/actions";

import { getUserUpdatePath } from "../../helpers/Common";

export const setSession = (data) => {
  return async (dispatch) => {
    if (data.cookie) {
      await AsyncStorage.setItem("aiocookie", data.cookie);
    } else {
      await AsyncStorage.removeItem("aiocookie");
    }
    await AsyncStorage.removeItem("inbox");
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { email: data.email, cookie: data.cookie },
    });
  };
};

export const clearSession = (data) => {
  return async (dispatch) => {
    await AsyncStorage.removeItem("aiocookie");
    await AsyncStorage.removeItem("inbox");
    dispatch({
      type: CLEAR_SESSION,
    });
    dispatch({
      type: INBOX_SESSION_FAILURE,
    });
  };
};

export const setSessionFromCache = (data) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { cookie: data.cookie },
    });
  };
};

export const setExpoPushToken = (expo_push_token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_EXPO_PUSH_TOKEN,
      payload: { expo_push_token: expo_push_token },
    });
  };
};

export const updateUser = (form_data) => {
  return (dispatch) => {
    let url = getUserUpdatePath();
    fetch(url, {
      method: "PUT",
      credentials: "include",
      body: form_data,
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((error) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then((json) => {
        dispatch({ type: USER_UPDATE_SUCCESS, payload: json });
        alert("Profile has been updated succesfully!");
      })
      .catch((error) => {
        console.log("Update User Error", error);
      });
  };
};
