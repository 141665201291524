import { createStore, applyMiddleware } from "redux";
import logger, { createLogger } from "redux-logger";
import reducer from "./reducers";
import thunk from "redux-thunk";

// const loggerMiddleware = createLogger({
//   // stateTransformer: (state) => state.toJS(),
//   predicate: () => process.env.NODE_ENV !== "production",
// });

const createStoreWithMiddleware = applyMiddleware(
  thunk,
  // loggerMiddleware
)(createStore);

export default (initialState) =>
  createStoreWithMiddleware(reducer, initialState);
