import React, { Component } from "react";
import { Platform, View, StyleSheet } from "react-native";
import { Appbar } from "react-native-paper";
import { WebView } from "react-native-webview";
import { connect } from "react-redux";
import {
  setSession,
  clearSession,
  setSessionFromCache,
} from "../store/actions/session";
import { saveInboxSession, getInboxes } from "../store/actions/inbox";

class ForgotPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
    };
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <Appbar.Header style={{ backgroundColor: "#7480FF" }}>
          <Appbar.BackAction
            color="white"
            onPress={() => {
              this.props.navigation.goBack();
            }}
          />
          <Appbar.Content title="Forgot Password" color="white" />
        </Appbar.Header>
        {Platform.OS !== "web" && (
          <WebView
            source={{ uri: "https://app.autochat.io/users/password/new" }}
            
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    navigation: ownProps.navigation,
    email: state.session.email,
    cookie: state.session.cookie,
  };
};

export default connect(mapStateToProps, {
  setSession,
  clearSession,
  setSessionFromCache,
  saveInboxSession,
  getInboxes,
})(ForgotPasswordScreen);

const styles = StyleSheet.create({});
