import {
  CONVERSATION_ADD,
  CONVERSATION_LOADING,
  CONVERSATION_MODE,
  CONVERSATION_STATUS,
  CONVERSATION_USER_LOAD,
  CONVERSATION_USER_CLEAR,
} from "../../constants/actions";

const initialState = {
  mode: "open",
  conversationIds: [],
  conversations: {},
  loading: false,
  storeData: null,
  conversationUser: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONVERSATION_MODE:
      return { ...state, mode: action.payload.mode };
    case CONVERSATION_STATUS:
      let data = { ...state.conversations, ...action.payload };
      return { ...state, conversations: data };
    case CONVERSATION_LOADING:
      return {
        conversationIds: [],
        conversations: {},
        loading: !(action.payload && action.payload.completed),
        mode: state.mode,
        Products: state.products,
        storeData: state.storeData,
      };
    case CONVERSATION_USER_LOAD:
      return { ...state, conversationUser: action.payload };
    case CONVERSATION_USER_CLEAR:
      return { ...state, conversationUser: null };
    case CONVERSATION_ADD:
      let list = action.payload;
      let conversationIds = state.conversationIds;

      let newObj = {};
      list.forEach(c => {
        newObj[c.id] = c.data;
      });
      let conversations = Object.assign({}, state.conversations, newObj);
      list.forEach(c => {
        let index = conversationIds.indexOf(c.id);
        if (index >= 0) {
          conversationIds.splice(index, 1);
        }
        index = 0;
        let c1 = c.data;
        while(index < conversationIds.length) {
          let c2 = conversations[ conversationIds[index] ];

          if (
            c1 &&
            c1.inbox &&
            c1.inbox.sortOrder &&
            c2 &&
            c2.inbox &&
            c2.inbox.sortOrder
          ) {
            if (c2.inbox.sortOrder < c1.inbox.sortOrder) {
              break;
            }
          } else if (c2 && c2.inbox && c2.inbox.sortOrder) {
            break;
          }
          index += 1;
        }
        conversationIds.splice(index, 0, c.id);
      });

      return {
        conversationIds,
        conversations,
        loading: false,
        mode: state.mode,
        products: state.products,
        storeData: state.storeData,
      };

    default:
      return state;
  }
};
