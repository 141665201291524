import store from "../";
import { db, firebaseDB } from "../../firebase/db";
import * as Analytics from "expo-firebase-analytics";

import {
  GET_CUSTOMER_ORDERS_SUCCESS,
  GET_SEARCH_PRODUCTS_SUCCESS,
} from "../../constants/actions";
import { addMessage } from "../actions/messages";

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  alert("Error");
  return response;
}
export const getSearchProducts = (url, token) => {
  url = url + "&api_token=" + token;
  return (dispatch) => {
    // dispatch({
    //   type: GET_SEARCH_PRODUCTS_REQUEST,
    // });
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: GET_SEARCH_PRODUCTS_SUCCESS,
          payload: res,
        });
      })
      .catch((error) => {
        console.log(error);
        // dispatch({
        //   type: actionType.GET_SEARCH_PRODUCTS_ERROR,
        //   payload: error,
        // });
      });
  };
};

export const getCustomerOrders = (url, token) => {
  url = url + "?api_token=" + token;
  return (dispatch) => {
    // dispatch({
    //   type: actionType.GET_CUSTOMER_ORDERS_REQUEST,
    // });
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: GET_CUSTOMER_ORDERS_SUCCESS,
          payload: res,
        });
      })
      .catch((error) => {
        console.log(error);
        // dispatch({
        //   type: actionType.GET_CUSTOMER_ORDERS_ERROR,
        //   payload: error,
        // });
      });
  };
};
