import Constants from "expo-constants";
import { Platform } from "react-native";

const ENV = {
  dev: {
    app_path: "https://app-dev.autochat.io",
    api_path: "https://api-dev.autochat.io",
    firebase_api_key: "AIzaSyDTkNp1ZAz4eMnb9NekjnEsHwUnpx5dU5M",
    firebase_auth_domain: "autochatio-dev.firebaseapp.com",
    firebase_database_url: "https://autochatio-dev.firebaseio.com/",
    firebase_project_id: "autochatio-dev",
    firebase_storage_bucket: "autochatio-dev.appspot.com",
    firebase_messaging_senderid: "824672113647",
    firebase_app_id: "1:304645296509:web:ec372d79a34418b3227c14",
    firebase_measurementId: "G-T6Z5W0RBJW",
  },
  prod: {
    app_path: "https://app.autochat.io",
    api_path: "https://api.autochat.io",
    firebase_api_key: "AIzaSyCdVCjee1jzdzby4BVBEDeqLqmdEST0uvo",
    firebase_auth_domain: "autochatio-prod.firebaseapp.com",
    firebase_database_url: "https://autochatio-prod.firebaseio.com/",
    firebase_project_id: "autochatio-prod",
    firebase_storage_bucket: "autochatio-prod.appspot.com",
    firebase_messaging_senderid: "304645296509",
    firebase_app_id: "1:304645296509:web:ec372d79a34418b3227c14",
    firebase_measurementId: "G-T6Z5W0RBJW",
  },
};

function getEnvVars(env = "") {
  if (Platform.OS === "web") {
    env = /inbox.autochat.io$/.test(window.location.hostname) ? "prod" : "dev";
  }
  if (env === null || env === undefined || env === "") return ENV.dev;
  if (env.indexOf("dev") !== -1) return ENV.dev;
  if (env.indexOf("prod") !== -1) return ENV.prod;
}

export default getEnvVars(Constants.manifest.releaseChannel);
