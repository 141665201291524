import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import {
  Platform,
  AsyncStorage,
  StatusBar,
  StyleSheet,
  View,
  AppState,
  Vibration,
} from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
// import { Notifications, AppLoading } from "expo";
import AppLoading from "expo-app-loading";
import * as Notifications from "expo-notifications";
import { Asset } from "expo-asset";
import { Ionicons } from "@expo/vector-icons";
import * as Font from "expo-font";

import LoginScreen from "./screens/LoginScreen";
import AgentInfoScreen from "./screens/AgentInfoScreen";
import InboxesScreen from "./screens/InboxesScreen";
import ConversationsScreen from "./screens/ConversationsScreen";
import ConversationScreen from "./screens/ConversationScreen";
import UserInfoScreen from "./screens/UserInfoScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";

import { Provider } from "react-redux";
import store from "./store";

import { init as firestoreInit } from "./firebase/db";
import { SET_EXPO_PUSH_TOKEN } from "./constants/actions";

const Stack = createStackNavigator();

import { decode, encode } from "base-64";
if (!global.btoa) {
  global.btoa = encode;
}
if (!global.atob) {
  global.atob = decode;
}

export default class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      expoPushToken: "",
      notification: {},
      appState: "unknown",
    };
  }

  _handleAppStateChange = (nextAppState) => {
    if (
      this.state.appState.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      console.log("App has come to the foreground!");
    }
    this.setState({ appState: nextAppState });
  };

  async componentDidMount() {
    firestoreInit();
    if (Platform.OS !== "web") {
      AppState.addEventListener("change", this._handleAppStateChange);
      this._notificationSubscription = Notifications.addListener(
        this._handleNotification
      );
      Notifications.dismissAllNotificationsAsync();
    }
    if (Platform.OS === "web") {
      // getInboxes will check the session
      await AsyncStorage.setItem("aiocookie", "true");
    }
  }

  componentWillUnmount() {
    if (Platform.OS !== "web") {
      AppState.removeEventListener("change", this._handleAppStateChange);
    }
  }

  _handleNotification = (notification) => {
    //console.log("Notification", this.state.appState, store.getState().messages.conversationId, notification);
    if (
      this.state.appState === "active" &&
      notification.origin === "received"
    ) {
      if (
        !notification.data ||
        notification.data.conversationId !==
          store.getState().messages.conversationId
      ) {
        Vibration.vibrate();
      }
      Notifications.dismissNotificationAsync(notification.notificationId);
      //Notifications.dismissAllNotificationsAsync();
    }
    this.setState({ notification: notification });
  };

  async _cacheResourcesAsync() {
    let { default: assets } = require("./constants/avatars");
    assets.push(require("./assets/images/icon.png"));

    let cache = assets.map((asset) => {
      return Asset.fromModule(asset).downloadAsync();
    });

    cache.push(
      Font.loadAsync({
        ...Ionicons.font,
        "space-mono": require("./assets/fonts/SpaceMono-Regular.ttf"),
      })
    );
    return Promise.all(cache);
  }

  render() {
    console.disableYellowBox = true;

    if (!this.state.isReady) {
      return (
        <AppLoading
          startAsync={this._cacheResourcesAsync}
          onFinish={() => this.setState({ isReady: true })}
          onError={console.warn}
        />
      );
    }

    return (
      <Provider store={store}>
        <PaperProvider>
          <View style={styles.container}>
            {Platform.OS === "ios" && <StatusBar barStyle="dark-content" />}
            <NavigationContainer>
              <Stack.Navigator initialRouteName="Login">
                <Stack.Screen
                  name="Login"
                  component={LoginScreen}
                  options={{ title: "Autochat.io Inbox", headerShown: false }}
                />
                <Stack.Screen
                  name="AgentInfo"
                  component={AgentInfoScreen}
                  options={{ title: "Profile", headerShown: false }}
                />
                <Stack.Screen
                  name="Inboxes"
                  component={InboxesScreen}
                  options={{ title: "Inboxes", headerShown: false }}
                />
                <Stack.Screen
                  name="Conversations"
                  component={ConversationsScreen}
                  options={{ title: "Recent Chats", headerShown: false, gestureEnabled: false }}
                />
                <Stack.Screen
                  name="Conversation"
                  component={ConversationScreen}
                  options={{ title: "Conversation", headerShown: false }}
                />
                <Stack.Screen
                  name="UserInfo"
                  component={UserInfoScreen}
                  options={{ title: "UserInfo", headerShown: false }}
                />
                <Stack.Screen
                  name="ForgotPassword"
                  component={ForgotPasswordScreen}
                  options={{ title: "Forgot Password", headerShown: false }}
                />
              </Stack.Navigator>
            </NavigationContainer>
          </View>
        </PaperProvider>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
