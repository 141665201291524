import React, { useState } from "react";
import { View, Image } from "react-native";
import { Text, List, Divider, IconButton, Badge } from "react-native-paper";
import TimeAgo from "react-native-timeago";
import avatars from "../constants/avatars";

var moment = require("moment");
moment.updateLocale("en", {
  relativeTime: {
    s: "Live",
    future: "%s",
    ss: "%d seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 Month",
    MM: "%d Months",
    y: "1 Year",
    yy: "%d Years",
  },
});

function ConversationLeftBar(props) {
  const { conversation, session, conversationId } = props;

  let imageid = 0;
  if (
    conversation &&
    conversation.sender &&
    conversation.sender.uniqueIdentifier
  ) {
    imageid = conversation.sender.uniqueIdentifier;
  } else if (conversationId) {
    for (let i = 0; i < conversationId.length; i++) {
      imageid += conversationId.charCodeAt(i);
    }
  }
  imageid = imageid % 18;

  return (
    <View
      style={{
        alignSelf: "center",
      }}
    >
      <Image
        source={avatars[imageid]}
        style={{ width: 40, height: 40 }}
        resizeMode="contain"
      />
    </View>
  );
}

function ConversationRightBar(props) {
  const { conversation, session, conversationId, currentUser } = props;

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ paddingLeft: 10 }}>
        {currentUser != null &&
          currentUser.cart &&
          currentUser.cart.items &&
          currentUser.cart.items.length != 0 && (
            <Badge style={{ zIndex: 10, position: "absolute", top: 0 }}>
              {currentUser.cart.items.length}
            </Badge>
          )}
        {conversation.cart && conversation.cart.total_price > 0 && (
          <IconButton
            icon="cart-outline"
            color={"white"}
            size={30}
            style={{
              margin: 0,
            }}
          />
        )}
      </View>
      {/* <ProfileImage
        conversation={conversation}
        name={getAssigned(conversation)}
        className="outIntegration"
      /> */}
    </View>
  );
}

export default function ConversationItem(props) {
  const {
    conversationId,
    conversation,
    session,
    headerView,
    currentUser,
  } = props;

  if (!conversation) {
    return <View></View>;
  }

  let location = null;
  let name =
    conversation.ecommerce &&
    conversation.ecommerce.customer &&
    conversation.ecommerce.customer.name;
  if (
    !name &&
    conversation.sender &&
    conversation.sender.details &&
    conversation.sender.details.name
  ) {
    if (conversation.sender.details.email) {
      name = `${conversation.sender.details.name} (${conversation.sender.details.email})`;
    } else {
      name = conversation.sender.details.name;
    }
  }
  if (
    !name &&
    conversation.ecommerce &&
    conversation.ecommerce.customer &&
    conversation.ecommerce.customer.location
  ) {
    location = conversation.ecommerce.customer.location;
  }

  return (
    <View key={conversationId}>
      <List.Item
        key={conversationId}
        titleStyle={{ height: 0 }}
        description={() => {
          const { conversationId, conversation, session, headerView } = props;
          let live =
            conversation.lastSeenAt &&
            new Date().getTime() - conversation.lastSeenAt.toMillis() < 60000;

          return (
            <View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {location != null && (
                  <IconButton
                    icon="map-marker"
                    color={"white"}
                    size={16}
                    style={{
                      marginLeft: -10,
                      marginRight: -5,
                      marginVertical: 0,
                    }}
                  />
                )}
                <Text
                  style={{
                    color: "white",
                    opacity: 5,
                    fontWeight: "bold",
                  }}
                >
                  {name != null ? name : location}
                </Text>
              </View>

              <TimeAgo
                time={
                  conversation.lastSeenAt && conversation.lastSeenAt.toDate()
                }
                hideAgo={live ? true : false}
                interval={40000}
                style={{
                  color: live ? "green" : "black",
                  fontWeight: live ? "bold" : "normal",
                }}
              />
            </View>
          );
        }}
        left={() => (
          <ConversationLeftBar
            conversationId={conversationId}
            conversation={conversation}
            session={session}
            headerView={props.headerView}
          />
        )}
        right={() => (
          <ConversationRightBar
            conversationId={conversationId}
            conversation={conversation}
            session={session}
            headerView={props.headerView}
            currentUser={currentUser}
          />
        )}
        onPress={props.onPress}
      />
      <Divider />
    </View>
  );
}
