import React, { Component } from "react";
import { Platform, View, StyleSheet, Text } from "react-native";
import {
  ScrollView,
  FlingGestureHandler,
  State,
  Directions,
} from "react-native-gesture-handler";
import {
  Button,
  ActivityIndicator,
  IconButton,
  Avatar,
  Appbar,
  TextInput,
} from "react-native-paper";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";

import { connect } from "react-redux";
import { clearSession, updateUser } from "../store/actions/session";

class AgentInfoScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      name: null,
      profile_image_url: null,
      password: null,
      confirm_password: null,
      notifications: Platform.OS === "web" ? false : true,
      errorText: null,
      successText: null,
      isLoading: false,
      isLoadingCache: true,
      expo_push_token: null,
      profile_image_changed: false,
    };
  }

  async updateUserDetails() {
    this.setState({ errorText: null, isLoading: true });
    let form_data = new FormData();
    form_data.append("name", this.state.name);
    if (this.state.password) {
      if (this.state.password !== this.state.confirm_password) {
        this.setState({ errorText: "Passwords are not matching..." });
        return null;
      } else {
        form_data.append("password", this.state.password);
      }
    }
    if (this.state.profile_image_changed == true) {
      let imageUri = this.state.profile_image_url;
      var mime = require("mime");
      const newImageUri = "file:///" + imageUri.split("file:/").join("");

      form_data.append("image", {
        uri: newImageUri,
        type: mime.getType(newImageUri),
        name: newImageUri.split("/").pop(),
      });
    }

    this.props.updateUser(form_data);
  }
  logout() {
    this.props.clearSession();
    this.setState({ password: null });
    this.props.navigation.popToTop();
  }

  async UNSAFE_componentWillMount() {
    if (Platform.OS !== "web") {
      const {
        status,
      } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert("Sorry, we need camera roll permissions to make this work!");
      }
    }
  }

  async componentDidMount() {
    this.setState({
      email: this.props.session.user.email,
      name: this.props.session.user.name,
      profile_image_url: this.props.session.user.image,
    });
    this.setState({
      password: null,
      confirm_password: null,
    });
    this.setState({ isLoadingCache: false });
  }

  render() {
    const pickImage = async () => {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 4],
        quality: 1,
      });

      if (!result.cancelled) {
        this.setState({
          profile_image_url: result.uri,
          profile_image_changed: true,
        });
      }
    };
    if (this.state.isLoadingCache) {
      return (
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" color="#7480FF" />
        </View>
      );
    }

    return (
      <FlingGestureHandler
        direction={Directions.LEFT}
        onHandlerStateChange={({ nativeEvent }) => {
          if (nativeEvent.state === State.ACTIVE) {
            this.props.navigation.goBack();
          }
        }}
      >
        <View style={{ flex: 1 }}>
          <Appbar.Header style={{ backgroundColor: "#7480FF" }}>
            <Appbar.BackAction
              color="white"
              onPress={() => {
                this.props.navigation.goBack();
              }}
            />
            <Appbar.Content title="Profile" color="white" />
            <Button
              icon="logout"
              mode="text"
              uppercase={false}
              color="white"
              style={{}}
              onPress={() => this.logout()}
            >
              Sign-out
            </Button>
          </Appbar.Header>
          <ScrollView style={{ marginBottom: 20 }}>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {this.state.profile_image_url == null ? (
                <IconButton
                  icon="account-circle"
                  color={"black"}
                  size={100}
                  style={{ padding: 0, backgroundColor: "red" }}
                />
              ) : (
                <Avatar.Image
                  size={124}
                  source={{
                    uri: this.state.profile_image_url,
                  }}
                  style={{ marginTop: 20 }}
                />
              )}

              <Text
                style={{
                  marginVertical: 10,
                  fontWeight: "bold",
                  textDecorationLine: "underline",
                }}
                onPress={pickImage}
              >
                Change
              </Text>
              <Text
                style={{
                  marginVertical: 4,
                }}
              >
                (Image size should be less than 1 MB)
              </Text>
              <Text style={{}}>(Image format: .png , .jpg)</Text>
            </View>

            <View style={{ width: "85%", alignSelf: "center" }}>
              <Text
                style={{
                  marginBottom: 10,
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                Email
              </Text>
              <TextInput
                mode="outlined"
                autoCompleteType="email"
                dense={true}
                keyboardType="email-address"
                textContentType="emailAddress"
                onChangeText={(text) => this.setState({ email: text })}
                value={this.state.email || ""}
                style={styles.input}
                editable={false}
              />

              <Text style={{ marginVertical: 10, fontWeight: "bold" }}>
                Display Name
              </Text>
              <TextInput
                mode="outlined"
                dense={true}
                onChangeText={(text) =>
                  this.setState({ name: text, errorText: "" })
                }
                value={this.state.name || ""}
                style={styles.input}
                theme={{ colors: { primary: "#7480FF" } }}
              />

              <Text style={{ marginVertical: 10, fontWeight: "bold" }}>
                New Password
              </Text>
              <TextInput
                mode="outlined"
                dense={true}
                autoCompleteType="off"
                autoCorrect={false}
                secureTextEntry={true}
                textContentType="password"
                onChangeText={(text) =>
                  this.setState({ password: text, errorText: "" })
                }
                value={this.state.password || ""}
                style={styles.input}
                theme={{ colors: { primary: "#7480FF" } }}
              />

              <Text style={{ marginVertical: 10, fontWeight: "bold" }}>
                Confirm New Password
              </Text>
              <TextInput
                mode="outlined"
                dense={true}
                autoCompleteType="off"
                autoCorrect={false}
                secureTextEntry={true}
                textContentType="password"
                onChangeText={(text) =>
                  this.setState({ confirm_password: text, errorText: "" })
                }
                value={this.state.confirm_password || ""}
                style={styles.input}
                theme={{ colors: { primary: "#7480FF" } }}
              />
              <Text
                style={{ color: "red", textAlign: "center", paddingTop: 10 }}
              >
                {this.state.errorText}
              </Text>
            </View>
          </ScrollView>
          {this.props.session && (
            <Button
              mode="contained"
              uppercase={false}
              disabled={
                this.props.session.user.name == this.state.name
                  ? this.state.password ||
                    this.state.profile_image_changed == true
                    ? false
                    : true
                  : false
              }
              style={{
                marginBottom: 30,
                backgroundColor: "#7480FF",
                alignSelf: "center",
                width: "85%",
              }}
              onPress={() => {
                this.updateUserDetails();
              }}
            >
              Update
            </Button>
          )}
        </View>
      </FlingGestureHandler>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cookie: state.session.cookie,
    session: state.inbox.session,
    userUpdateStatus: state.inbox.userUpdateStatus,
  };
};

export default connect(mapStateToProps, {
  clearSession,
  updateUser,
})(AgentInfoScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
  input: {
    alignSelf: "center",
    width: "100%",
    backgroundColor: "transparent",
  },
});
