import { combineReducers } from "redux";
import session from "./session";
import inbox from "./inbox";
import conversations from "./conversations";
import messages from "./messages";
import store from "./store";

export default combineReducers({
  session,
  inbox,
  conversations,
  messages,
  store,
});
