import {
  GET_CUSTOMER_ORDERS_SUCCESS,
  GET_SEARCH_PRODUCTS_SUCCESS,
} from "../../constants/actions";

const initialState = {
  products: null,
  orders: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      };
    case GET_SEARCH_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload };
    default:
      return state;
  }
};
