import React, { Component, useState } from "react";
import {
  Platform,
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
} from "react-native";
import {
  Text,
  Button,
} from "react-native-paper";

export default function AppStoreLinks(props) {
  return (
    <View style={{ flex: 1, flexDirection: "column", marginTop:30 }}>
      {Platform.OS === "web" && (
        <View style={{flex: 1, flexDirection: "row", justifyContent: "center", flexGrow: 0}}>
          <TouchableOpacity
            style={{width: 200}}
            onPress={() => {
              window.location.href =
                "https://play.google.com/store/apps/details?id=io.autochat.inbox";
            }}
          >
            <Image
              source={require("../assets/images/google-play-badge.png")}
              style={{
                marginTop: 10,
                width: "100%",
                height: 100,
                resizeMode: "contain",
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{width: 200}}
            onPress={() => {
              window.location.href =
                "https://apps.apple.com/us/app/autochat-inbox/id1518996007";
            }}
          >
            <Image
              source={require("../assets/images/app-store-badge.png")}
              style={{
                marginTop: 10,
                width: "100%",
                height: 100,
                resizeMode: "contain",
              }}
            />
          </TouchableOpacity>
        </View>
      )}
      {Platform.OS === "web" && (
        <View style={{flex: 1}}>
        <Button
          icon="laptop"
          mode="text"
          compact={true}
          uppercase={false}
          contentStyle={{ fontSize: 10 }}
          onPress={() => {
            window.location.href =
              "https://inbox.autochat.io?forceweb=true";
          }}
        >
          Open In Desktop Browser
        </Button>
        </View>
      )}
    </View>
  );
}
